import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  Typography,
  DialogContent,
  DialogActions,
  Grid,
  List,
  ListItem,
  Box,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { StudyEntity, useStudiesQuery, useStudyArmsByStaffQuery } from '../../../graphql';
import { useCurrentUser, useUser } from '../../../states';

interface Properties {
  open: boolean;
  onClose: () => void;
}

export const SelectStudySpaceModal = ({ open, onClose }: Properties) => {
  const hasRunEffect = useRef(false);
  const { setStudyArmId, setStudyId, studyId, studyArmId } = useUser();
  const [selectedStudyId, setSelectedStudyId] = useState<number | null | undefined>(studyId);
  const [selectedStudyArmId, setSelectedStudyArmId] = useState<number | null | undefined>(
    studyArmId,
  );
  const { data } = useCurrentUser();
  const { t } = usePreferredTranslation();
  const { data: studiesData, loading: studiesLoading } = useStudiesQuery({});

  const { data: studyArmsData, loading: studyArmsLoading } = useStudyArmsByStaffQuery({
    variables: {
      userId: Number(data?.user?.id),
      studyId: Number(selectedStudyId),
    },
    skip: !data?.user?.id,
    fetchPolicy: 'cache-and-network',
  });

  const { data: selectedStudyArmsData } = useStudyArmsByStaffQuery({
    variables: {
      userId: Number(data?.user?.id),
      studyId: Number(studyId),
    },
    skip: !data?.user?.id,
    fetchPolicy: 'cache-and-network',
  });

  const handleSelectStudy = (study: StudyEntity) => {
    setSelectedStudyId(study.id);
  };

  const handleSave = () => {
    setStudyId(Number(selectedStudyId));

    // Find the study arm data for the selected study
    const selectedStudyDataArm = studyArmsData?.studyArmsByStaff?.find(
      (arm) => Number(arm.id) === Number(selectedStudyArmId),
    );

    // If the selected study arm is not in the selected study, select the first arm
    if (!selectedStudyDataArm && (studyArmsData?.studyArmsByStaff?.length || 0) > 0) {
      setStudyArmId(Number(studyArmsData?.studyArmsByStaff?.[0]?.id));
    } else {
      setStudyArmId(Number(selectedStudyArmId));
    }

    onClose();
  };

  const handleCancel = () => {
    setSelectedStudyArmId(studyArmId);
    setSelectedStudyId(studyId);
    onClose();
  };

  useEffect(() => {
    setSelectedStudyId(studyId);
    setSelectedStudyArmId(studyArmId);
  }, [studyId, studyArmId]);

  useEffect(() => {
    if (hasRunEffect.current) return;

    if (studyId && studyArmId) {
      const selectedStudyDataArm = selectedStudyArmsData?.studyArmsByStaff?.find(
        (arm) => Number(arm.id) === studyArmId,
      );
      if (!selectedStudyDataArm && selectedStudyArmsData?.studyArmsByStaff?.length) {
        hasRunEffect.current = true;
        setStudyArmId(Number(selectedStudyArmsData?.studyArmsByStaff?.[0]?.id));
      }
    }

    if (studyId && !studyArmId && selectedStudyArmsData?.studyArmsByStaff?.length) {
      const armId = selectedStudyArmsData?.studyArmsByStaff?.[0]?.id;
      if (!armId) return;
      hasRunEffect.current = true;
      setStudyArmId(Number(armId));
    }
  }, [selectedStudyArmsData?.studyArmsByStaff]);

  return (
    <Dialog open={Boolean(open)} fullWidth maxWidth="sm" onClose={handleCancel}>
      <DialogTitle>{t('Select a space')}</DialogTitle>
      <DialogContent>
        {studiesLoading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            width="100%"
          >
            <CircularProgress color="primary" size={30} sx={{ my: 5 }} />
          </Box>
        ) : (
          <Grid container spacing={0.2}>
            <Grid item xs={6}>
              <Typography
                color="text.secondary"
                sx={{ fontSize: '13px', fontWeight: 500, letterSpacing: 1.5 }}
              >
                {t('STUDY')}
              </Typography>
              <List sx={{ maxHeight: '350px', overflow: 'auto' }}>
                {studiesData?.studies.items.map((item) => {
                  return (
                    <ListItem
                      key={item.id}
                      onClick={() => handleSelectStudy(item)}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor:
                          selectedStudyId === item.id ? 'primary.main' : 'transparent',
                        color: selectedStudyId === item.id ? 'white' : 'black',
                      }}
                    >
                      <Typography variant="body2">{item.displayName}</Typography>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={5} sx={{ ml: 0.5 }}>
              <Typography
                color="text.secondary"
                sx={{ fontSize: '13px', fontWeight: 500, letterSpacing: 1.5 }}
              >
                {t('ARM')}
                {studyArmsLoading && <CircularProgress color="primary" size={12} sx={{ ml: 1 }} />}
              </Typography>
              <List sx={{ maxHeight: '350px', overflow: 'auto' }}>
                {studyArmsData?.studyArmsByStaff?.map((item) => {
                  return (
                    <ListItem
                      key={item.id}
                      onClick={() => setSelectedStudyArmId(Number(item.id))}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor:
                          selectedStudyArmId === Number(item.id) ? 'primary.main' : 'transparent',
                        color: selectedStudyArmId === Number(item.id) ? 'white' : 'black',
                      }}
                    >
                      <Typography variant="body2">{item.name}</Typography>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{t('Cancel')}</Button>
        <Button
          onClick={handleSave}
          disabled={!selectedStudyId}
          variant="contained"
          sx={{ bgcolor: 'primary.main' }}
        >
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
