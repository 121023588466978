import React, { ReactElement } from 'react';
import { Box, SxProps } from '@mui/material';
import { CheckboxField, DropdownField, InputField, RadioField, TextareaField } from '../atoms';
import AnswerNote from './AnswerNote';
import { AssessmentQuestionEntity } from '../../graphql';
import QuestionTypes from '../organisms/EditableQuestion/QuestionTypes';
import { BlockSelector } from '../atoms/BlockSelector';
import { PlusMinusField } from '../atoms/InputField/PlusMinusField';
import { SliderField } from '../atoms/SliderField';
import { ImageSelector } from '../atoms/ImageSelector';

export interface AnswerableAssessmentQuestionEntity extends AssessmentQuestionEntity {
  answer: string[];
  libraryId: string;
  comment: string;
}

export const BLOCK_SELECTOR_TYPES: any = {
  [QuestionTypes.boolean]: ['Yes', 'No'],
  [QuestionTypes.range5]: Array.from({ length: 5 }, (_, index) => `${index + 1}`),
  [QuestionTypes.range10]: Array.from({ length: 10 }, (_, index) => `${index + 1}`),
};

export const renderQuestion = (
  question: AnswerableAssessmentQuestionEntity,
  handleQuestionAnswered: (questionId: string, answer: any) => void,
): ReactElement | null => {
  if (!question) return null;

  switch (question.type) {
    case QuestionTypes.range10:
    case QuestionTypes.range5: {
      return (
        <BlockSelector
          key={question.id}
          {...question}
          value={question.answer ? question.answer[0] : ''}
          showBoldLabel
          onChange={(value: string) => handleQuestionAnswered(question.id, [value])}
          options={BLOCK_SELECTOR_TYPES[question.type] || []}
        />
      );
    }
    case QuestionTypes.plusMinus: {
      return (
        <PlusMinusField
          key={question.id}
          {...question}
          value={question.answer ? question.answer[0] : ''}
          onChange={(value: string) => handleQuestionAnswered(question.id, [value])}
        />
      );
    }
    case QuestionTypes.slider: {
      return (
        <SliderField
          key={question.id}
          {...question}
          min={question.meta?.minValue ?? question.meta?.minValue ?? 0}
          max={question.meta?.maxValue ?? question.meta?.maxValue ?? 10}
          step={question.meta?.stepSize ?? question.meta?.stepSize ?? 1}
          value={question.answer?.[0] || '0'}
          showBoldLabel
          onChange={(value: string) => handleQuestionAnswered(question.id, [value])}
        />
      );
    }
    case QuestionTypes.imagePicker: {
      return (
        <ImageSelector
          key={question.id}
          {...question}
          value={question.answer ? question.answer[0] : ''}
          showBoldLabel
          onChange={(value: string) => handleQuestionAnswered(question.id, [value])}
          options={JSON.parse(question.options || '[]')}
        />
      );
    }
    case 'radio': {
      return (
        <RadioField
          {...question}
          key={question.id}
          options={JSON.parse(question.options || '[]')}
          value={question.answer ? question.answer[0] : ''}
          onChange={(event) => handleQuestionAnswered(question.id, [event.target.value])}
          showBoldLabel
        />
      );
    }
    case QuestionTypes.boolean: {
      return (
        <RadioField
          {...question}
          key={question.id}
          options={
            JSON.parse(question.options || '[]').length === 0
              ? ['Yes', 'No']
              : JSON.parse(question.options || '[]')
          }
          value={question.answer ? question.answer[0] : ''}
          onChange={(event) => handleQuestionAnswered(question.id, [event.target.value])}
          showBoldLabel
        />
      );
    }
    case 'text': {
      return (
        <TextareaField
          key={question.id}
          {...question}
          value={question.answer ? question.answer[0] : ''}
          onChange={(event) => handleQuestionAnswered(question.id, [event.target.value])}
          showBoldLabel
        />
      );
    }

    case QuestionTypes.select: {
      return (
        <DropdownField
          data-testid={`dropdown-question-${question.id}`}
          key={question.id}
          {...question}
          value={question.answer ? question.answer[0] : ''}
          onChange={(event) => {
            handleQuestionAnswered(question.id, [event.target.value]);
          }}
          options={JSON.parse(question.options || '[]')}
          showBoldLabel
        />
      );
    }
    case 'checkbox':
    case QuestionTypes.multiselect: {
      return (
        <CheckboxField
          key={question.id}
          {...question}
          options={JSON.parse(question.options || '[]')}
          value={question.answer || []}
          onChange={(checkedValues: string[]) =>
            handleQuestionAnswered(question.id, checkedValues || [])
          }
          showBoldLabel
        />
      );
    }
    case 'input': {
      return (
        <InputField
          key={question.id}
          {...question}
          type={question.type}
          value={question.answer ? question.answer[0] : ''}
          onChange={(event) => handleQuestionAnswered(question.id, [event.target.value])}
          sx={{ pt: 3 }}
        />
      );
    }
    default: {
      return null;
    }
  }
};

interface QuestionProperties {
  questionData: AnswerableAssessmentQuestionEntity;
  handleCommentChange: (questionId: string, comment: string) => void;
  handleQuestionAnswered: (questionId: string, answer: any) => void;
  questionSx?: SxProps;
  answerNotePlaceholder?: string;
}

const Question: React.FC<QuestionProperties> = ({
  handleCommentChange,
  questionData,
  handleQuestionAnswered,
  questionSx,
  answerNotePlaceholder,
}) => {
  return (
    <Box>
      <Box sx={questionSx}>{renderQuestion(questionData, handleQuestionAnswered)}</Box>
      <AnswerNote
        defaultValue={questionData.comment}
        handleCommentChange={(value) => handleCommentChange(questionData.id, value)}
        placeholder={answerNotePlaceholder}
      />
    </Box>
  );
};

export default Question;
