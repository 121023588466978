import { useCallback } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { DialogCloseButton } from '../atoms';
import { usePreferredTranslation } from '../../hooks/usePreferredTranslation';

interface Properties extends DialogProps {
  title?: string;
  message?: string;
  submitButtonLabel?: string;
  loading?: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
}

export default function ConfirmDialog({
  title = 'Confirm',
  message = 'Are you sure?',
  submitButtonLabel = 'Ok',
  open = false,
  loading,
  onSubmit,
  onClose,
  ...rest
}: Properties) {
  const { t } = usePreferredTranslation();
  const handleClose = useCallback(() => onClose && onClose(), [onClose]);
  const handleSubmit = useCallback(() => onSubmit && onSubmit(), [onSubmit]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} {...rest}>
      <DialogTitle>
        <Stack spacing={1}>
          <Box sx={{ mb: 2 }}>
            <Typography component="p" variant="h5">
              {title}
            </Typography>
            <DialogCloseButton disabled={loading} onClick={handleClose} />
          </Box>

          <Typography variant="subtitle1" sx={{ fontWeight: 'normal' }}>
            {message}
          </Typography>

          <Stack alignItems="flex-end">
            <Stack direction="row" spacing={2} sx={{ my: 1 }}>
              <Button variant="outlined" disabled={loading} onClick={handleClose}>
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? <CircularProgress color="primary" size="1rem" /> : null}
                onClick={handleSubmit}
              >
                {submitButtonLabel}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </DialogTitle>
    </Dialog>
  );
}
