import { Button, CircularProgress, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { dayjs } from '../../../../utils/dayjs';
import {
  AssessmentResponsesQuery,
  useAddAssessmentAnswerMutation,
  useCoachingEventsQuery,
  useCreateAssessmentResponseMutation,
  useUpdateCoachingEventStatusMutation,
} from '../../../../graphql';
import { AnswerableAssessmentQuestionEntity } from '../../../callnotes/Question';
import { getDateStringFromDateTime } from '../../../../utils';
import { EventStatuses } from '../InfoHeader/type';
import CoachingCallStatus from '../InfoHeader/CoachingCallStatus';
import { parseQuestionValues } from './utils';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface LibraryActionProperties {
  assessmentResponses: AssessmentResponsesQuery | undefined;
  selectedLibrary: string | null;
  libraryQuestions: AnswerableAssessmentQuestionEntity[];
  selectedDate: Date;
  userId: number;
  refetchAssessments: any;
  translations?: { before: string; after: string }[] | undefined;
}

export default function LibraryActions({
  assessmentResponses,
  libraryQuestions,
  selectedDate,
  selectedLibrary,
  userId,
  refetchAssessments,
  translations,
}: LibraryActionProperties) {
  const { t } = usePreferredTranslation();
  const [callStatus, setCallStatus] = useState<EventStatuses | null>(null);

  const {
    data: coachingEventsData,
    loading: coachingEventsLoading,
    refetch: refetchCoachingEvents,
  } = useCoachingEventsQuery({
    variables: {
      userId,
    },
  });

  const [addAssessmentAnswer, { loading: addAssessmentLoading }] = useAddAssessmentAnswerMutation();
  const [createAssessmentResponse, { loading: createAssessmentLoading }] =
    useCreateAssessmentResponseMutation();
  const [updateCoachingEventStatus, { loading: updatingCoachingEventStatus }] =
    useUpdateCoachingEventStatusMutation();

  const handleCreatingAnser = useCallback(async () => {
    const answers = libraryQuestions.map((question) => {
      return {
        questionId: Number(question.id),
        values: parseQuestionValues(question, translations),
        comment: question.comment,
      };
    });

    await createAssessmentResponse({
      variables: {
        assessmentResponse: {
          library: Number(selectedLibrary),
          user: Number(userId),
          answers,
          answerDate: getDateStringFromDateTime(selectedDate) as unknown as Date,
        },
      },
    });
  }, [
    libraryQuestions,
    selectedDate,
    selectedLibrary,
    userId,
    createAssessmentResponse,
    translations,
  ]);

  const handleUpdatingAnswer = useCallback(
    async (responseId: string) => {
      const promises = libraryQuestions.map((question) => {
        return addAssessmentAnswer({
          variables: {
            id: Number(responseId),
            updateAssessmentAnswerInput: {
              values: parseQuestionValues(question, translations),
              questionId: Number(question.id),
              comment: question.comment,
              answerDate: getDateStringFromDateTime(selectedDate) as unknown as Date,
            },
          },
        });
      });

      await Promise.all(promises);
    },
    [libraryQuestions, selectedDate, addAssessmentAnswer, translations],
  );

  const handleSaveRecord = useCallback(async () => {
    if (!callStatus) {
      return;
    }

    const coachingEvents = coachingEventsData?.coachingEvents;

    const statusDate = getDateStringFromDateTime(selectedDate) as unknown as Date;

    const coachingEvent = coachingEvents?.find((_event) => {
      return (
        dayjs(_event.start).isSame(selectedDate, 'day') &&
        _event.libraryId === Number(selectedLibrary)
      );
    });

    if (coachingEvents?.length === 0 || !coachingEvent) {
      toast.error(t('No coaching events found, please select a valid date'));
      return;
    }

    const { errors } = await updateCoachingEventStatus({
      variables: {
        date: statusDate,
        id: coachingEvent.id,
        status: callStatus,
      },
    });

    if (errors) {
      toast.error(t('Failed to save call status'));
    }
  }, [callStatus, coachingEventsData, selectedDate, selectedLibrary, updateCoachingEventStatus]);

  const handleSave = useCallback(async () => {
    if (!callStatus) {
      toast.error(t('Please select a call status'));
      return;
    }

    const answerItems = assessmentResponses?.assessmentResponses?.items ?? [];
    const alreadyAnsweredResponse = answerItems.find((item) => item.library.id === selectedLibrary);
    await handleSaveRecord();

    await (alreadyAnsweredResponse
      ? handleUpdatingAnswer(alreadyAnsweredResponse.id)
      : handleCreatingAnser());

    toast.success(t('Record saved successfully'));

    refetchAssessments();
    refetchCoachingEvents();
  }, [
    assessmentResponses,
    selectedLibrary,
    refetchAssessments,
    callStatus,
    handleCreatingAnser,
    handleUpdatingAnswer,
    refetchCoachingEvents,
    handleSaveRecord,
  ]);

  const handleSetCallStatus = useCallback((_callStatus: EventStatuses) => {
    setCallStatus(_callStatus);
  }, []);

  useEffect(() => {
    const coachingEvents = coachingEventsData?.coachingEvents;

    if (!coachingEvents || coachingEvents.length === 0) {
      return;
    }

    const event = coachingEvents.find(
      (_event) =>
        dayjs(_event.start).isSame(selectedDate, 'day') &&
        _event.libraryId === Number(selectedLibrary),
    );

    const status = event?.status as EventStatuses;

    if (status) {
      setCallStatus(status);
    } else {
      setCallStatus(null);
    }
  }, [coachingEventsData, selectedDate, selectedLibrary]);

  const isSaving = addAssessmentLoading || createAssessmentLoading || updatingCoachingEventStatus;

  return (
    <Stack
      flexDirection="row"
      gap={2}
      sx={{ mt: 3, alignItems: 'center', justifyContent: 'flex-end' }}
    >
      <CoachingCallStatus
        callStatus={callStatus}
        loading={coachingEventsLoading}
        setCallStatus={handleSetCallStatus}
        disabled={isSaving}
      />
      <Button
        data-testid="finish-assessments-or-go-next"
        variant="contained"
        disabled={isSaving}
        startIcon={isSaving ? <CircularProgress color="primary" size="1rem" /> : null}
        onClick={handleSave}
      >
        {t('Save')}
      </Button>
    </Stack>
  );
}
