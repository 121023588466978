import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Select,
  MenuItem,
  Grid,
  Typography,
  Switch,
  CircularProgress,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { z } from 'zod';
import { toast } from 'react-toastify';
import { Delete } from '@mui/icons-material';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { IPlot, Plot } from './Plot';
import { PreviewChart } from './PreviewChart';
import { DataSourceDropdown } from './DataSourceDropdown/DataSourceDropdown';
import {
  AssessmentQuestionEntity,
  ChartTabOutputType,
  useAllJournalsQuery,
  useCreateChartMutation,
  useDeleteChartMutation,
  useGraphDataPointsLazyQuery,
  useGraphDataSourcesQuery,
  useLibrariesQuery,
  useLibraryLazyQuery,
  useUpdateChartMutation,
  useUserQuery,
} from '../../../graphql';
import { EditableTextField } from '../../atoms/EditableTextField';
import { GraphTypes } from '../../charts/ComposedChart';
import { transformDataSourceForCustomTypes } from '../../../utils/charts';

type Properties = {
  open: boolean;
  handleClose: () => void;
  handleSaveOrUpdate: () => void;
  chartData: GraphData; //  prop for passing existing chart data
  isUpdateMode?: boolean; // prop for indicating update mode
  profileId: number;
  categoryList: ChartTabOutputType[] | undefined;
};

const labelStyle = {
  marginBottom: '5px',
  marginLeft: '4px',
  fontSize: '14px',
};

export interface GraphData {
  id: string;
  xAxis: string;
  yAxis: string;
  graphType: GraphTypes;
  dataSource: AssessmentQuestionEntity | null;
  plots: IPlot[];
  categoryId: string;
  fullWidth: boolean;
  yAxis1: string;
  graphType1: GraphTypes;
  dataSource1: AssessmentQuestionEntity | null;
  plots1: IPlot[];
  title?: string;
  title1?: string | null;
  meta?: {
    dataSourceValue?: string;
    dataSourceType?: string;
    dataSourceValue1?: string;
    dataSourceType1?: string;
  };
}

const AxisValidation = z.object({
  xAxis: z.string({ required_error: 'X Axis is required' }).min(1, 'X Axis is required'),
  yAxis: z.string({ required_error: 'Y Axis is required' }).min(1, 'Y Axis is required'),
});

const GraphDataSchema = z.object({
  dataSource: z
    .record(z.any(), { required_error: 'Data source is required' })
    .refine((data) => data !== null, {
      message: 'Data source is required',
    }),
  graphType: z
    .enum(Object.values(GraphTypes).map(String) as [string, ...string[]])
    .refine((data) => data != null, {
      message: 'Graph type is required',
    }),
  plots: z.array(z.unknown()).nonempty('Choose plots for chart'),

  fullWidth: z.boolean(),
  title: z.string({ required_error: 'Chart title is required' }).min(1, 'Chart title is required'),
  categoryId: z.number({
    required_error: 'Category is required',
    invalid_type_error: 'Category is required',
  }),
});

export const ChartModal = ({
  handleClose,
  open,
  chartData,
  isUpdateMode,
  profileId,
  categoryList,
  handleSaveOrUpdate,
}: Properties) => {
  const { t } = usePreferredTranslation();
  const { data: userData } = useUserQuery({
    variables: {
      id: profileId,
    },
  });
  const nonPieRaidalDataSources = new Set([
    'exercise-question',
    'resistance-bands',
    'resistance-free-weight',
    'resistance-body-weight',
  ]);
  const [graphData, setGraphData] = useState<GraphData>(chartData);
  const [isCompareChart, setIsCompareChart] = useState(
    (chartData.dataSource1 && chartData.plots1?.length > 0) ?? false,
  );
  const [activeChart, setActiveChart] = useState(0);
  const [chartTitle, setChartTitle] = useState(chartData.title || 'Graph 1');
  const [chartTitle1, setChartTitle1] = useState(chartData.title1 || '');

  const { data } = useGraphDataSourcesQuery({
    variables: {
      where: {
        type: ['ivr', 'journal', 'assessment', ''],
      },
    },
  });

  const [graphDataQuery] = useGraphDataPointsLazyQuery();
  const [getLibrary] = useLibraryLazyQuery();
  const [deleteMutation, { loading: deleteChartLoading }] = useDeleteChartMutation();
  const [createChartMutation, { loading }] = useCreateChartMutation();
  const [updateChartMutation, { loading: updateChartLoading }] = useUpdateChartMutation();

  const transformDataSourcesOnUpdateMode = useCallback(async () => {
    const { dataSource, dataSource1, meta } = graphData;
    const transformedDataSource = await transformDataSourceForCustomTypes(
      dataSource!,
      graphDataQuery,
      getLibrary,
      profileId,
      meta?.dataSourceType,
      meta?.dataSourceValue,
    );
    const transformedDataSource1 = await transformDataSourceForCustomTypes(
      dataSource1!,
      graphDataQuery,
      getLibrary,
      profileId,
      meta?.dataSourceType1,
      meta?.dataSourceValue1,
    );

    setGraphData((previousGraphData) => ({
      ...previousGraphData,
      dataSource: transformedDataSource as AssessmentQuestionEntity,
      dataSource1: transformedDataSource1 as AssessmentQuestionEntity,
    }));
  }, []);

  useEffect(() => {
    if (isUpdateMode) {
      transformDataSourcesOnUpdateMode();
    }
  }, [isUpdateMode, transformDataSourcesOnUpdateMode]);

  const handleUpdateChart = async (result: GraphData) => {
    const { dataSource, dataSource1, graphType, plots, categoryId, graphType1, id, ...rest } =
      result;
    const { data: updatedData, errors } = await updateChartMutation({
      variables: {
        id: Number(chartData.id),
        payload: {
          ...rest,
          type: graphType,
          categoryId: Number(categoryId),
          dataSourceId: Number(dataSource?.id),
          plots: JSON.stringify(plots) as unknown as string[],
          title: rest.title as string,
          type1: isCompareChart ? graphType1 : '',
          dataSourceId1: dataSource1 ? Number(dataSource1?.id) : null,
          plots1: rest.plots1 ? (JSON.stringify(rest.plots1) as unknown as string[]) : undefined,
          title1: isCompareChart ? rest.title1 : '',
        },
      },
    });

    if (errors) {
      toast.error(errors[0]?.message);
    }

    if (updatedData?.updateChart) {
      handleSaveOrUpdate();
    }
  };

  const handleCreateChart = async (result: GraphData) => {
    const { dataSource, dataSource1, graphType, plots, categoryId, graphType1, id, ...rest } =
      result;
    const { data: createChartData, errors } = await createChartMutation({
      variables: {
        payload: {
          ...rest,
          userId: profileId,
          categoryId: Number(categoryId),
          type: graphType,
          type1: graphType1,
          dataSourceId: Number(dataSource?.id),
          dataSourceId1: dataSource1 ? Number(dataSource1?.id) : null,
          plots: JSON.stringify(plots) as unknown as string[],
          plots1: rest.plots1 ? (JSON.stringify(rest.plots1) as unknown as string[]) : undefined,
          title: rest.title as string,
          title1: isCompareChart ? rest.title1 : '',
        },
      },
    });

    if (errors) {
      toast.error(errors[0]?.message);
    }

    if (createChartData?.createChart) {
      handleSaveOrUpdate();
    }
  };

  const handleDelete = async () => {
    const { data: deleteChartData, errors } = await deleteMutation({
      variables: {
        id: Number(graphData.id),
      },
    });

    if (errors) {
      toast.error(errors[0]?.message);
    }

    if (deleteChartData?.deleteChart) {
      handleSaveOrUpdate();
    }
  };

  const handleSave = async () => {
    let result = GraphDataSchema.safeParse({
      ...graphData,
      title: chartTitle,
      categoryId: graphData.categoryId === '' ? null : Number(graphData.categoryId),
    });

    if (isCompareChart) {
      result = GraphDataSchema.safeParse({
        dataSource: graphData.dataSource1,
        plots: graphData.plots1,
        yAxis: graphData.yAxis1,
        xAxis: graphData.xAxis,
        title: chartTitle1,
        graphType: graphData.graphType1,
        fullWidth: graphData.fullWidth,
        categoryId: Number(graphData.categoryId),
      });
    }

    if (result.success) {
      // Check if graphType is not pie or radial
      if (
        graphData.graphType !== GraphTypes.PieChart &&
        graphData.graphType !== GraphTypes.RadialBarChart
      ) {
        // Validate xAxis and yAxis using AxisValidation schema
        const axisResult = AxisValidation.safeParse({
          xAxis: graphData.xAxis,
          yAxis: graphData.yAxis,
        });

        if (!axisResult.success) {
          const firstAxisError = axisResult.error.issues[0];
          toast.error(firstAxisError?.message);
          return;
        }
      }

      await (isUpdateMode
        ? handleUpdateChart({ ...graphData, title: chartTitle, title1: chartTitle1 })
        : handleCreateChart({ ...graphData, title: chartTitle, title1: chartTitle1 }));
    } else {
      const firstError = result.error.issues[0];
      toast.error(firstError?.message);
    }
  };

  const addNewPlot = (plotField: 'plots' | 'plots1') => {
    setGraphData((previousState) => ({
      ...previousState,
      [plotField]: [
        ...(previousState[plotField] || []),
        { id: uuid(), color: '', sourceId: undefined },
      ],
    }));
  };

  const handlePlotColorChange = (
    plotField: 'plots' | 'plots1',
    plotId: string,
    newColor: string,
  ) => {
    setGraphData((previousState) => ({
      ...previousState,
      [plotField]: previousState[plotField]?.map((plot) =>
        plot.id === plotId ? { ...plot, color: newColor } : plot,
      ),
    }));
  };

  const handlePlotSourceChange = (plotField: 'plots' | 'plots1', plotId: string, source: any) => {
    setGraphData((previousState) => ({
      ...previousState,
      [plotField]: previousState[plotField]?.map((plot) =>
        plot.id === plotId ? { ...plot, ...source } : plot,
      ),
    }));
  };

  const handlePlotDelete = (plotField: 'plots' | 'plots1', plotId: string) => {
    setGraphData((previousState) => ({
      ...previousState,
      [plotField]: previousState[plotField]?.filter((plot) => plot.id !== plotId),
    }));
  };

  const handleFieldChange =
    (field: keyof typeof graphData) => (value: string | boolean | AssessmentQuestionEntity) => {
      setGraphData((previous) => ({ ...previous, [field]: value }));
    };

  const handleDataSourceChange =
    (key: 'dataSource' | 'dataSource1') => async (value: AssessmentQuestionEntity) => {
      const transformedDataSource = await transformDataSourceForCustomTypes(
        value,
        graphDataQuery,
        getLibrary,
        profileId,
      );

      const meta =
        key === 'dataSource'
          ? {
              dataSourceValue: transformedDataSource?.label as string,
              dataSourceType: transformedDataSource?.type as string,
            }
          : {
              dataSourceValue1: transformedDataSource?.label as string,
              dataSourceType1: transformedDataSource?.type as string,
            };

      setGraphData((previousState) => ({
        ...previousState,
        [key]: transformedDataSource as AssessmentQuestionEntity,
        meta: {
          ...previousState.meta,
          ...meta,
        },
        ...(key === 'dataSource'
          ? {
              graphType: nonPieRaidalDataSources.has(meta.dataSourceType || '')
                ? GraphTypes.BarChart
                : previousState.graphType,
            }
          : {
              graphType1: nonPieRaidalDataSources.has(meta.dataSourceType1 || '')
                ? GraphTypes.BarChart
                : previousState.graphType1,
            }),
      }));
    };

  const handleCloseModal = () => {
    // eslint-disable-next-line no-alert
    const closeModal = window.confirm(
      'Are you sure you want to discard the changes? you will loose all your changes.',
    );

    if (closeModal) {
      handleClose();
    }
  };

  const handleCompareChange = () => {
    if (isCompareChart) {
      setGraphData((previousState) => ({
        ...previousState,
        dataSource1: null,
        graphType1: GraphTypes.SimpleChart,
        plots1: [],
        yAxis1: '',
      }));
      setActiveChart(0);
    }
    if (
      graphData.graphType === GraphTypes.PieChart ||
      graphData.graphType === GraphTypes.RadialBarChart
    ) {
      setGraphData((previousState) => ({
        ...previousState,
        dataSource1: null,
        graphType: GraphTypes.None,
        graphType1: GraphTypes.SimpleChart,
        plots1: [],
        yAxis1: '',
      }));
    }
    setChartTitle1('Graph 2');
    setIsCompareChart((previous) => !previous);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={Boolean(open)}
      onClose={loading || updateChartLoading || deleteChartLoading ? () => {} : handleCloseModal}
    >
      <DialogTitle>
        <Stack direction="row">
          {isUpdateMode ? t('Configure Chart') : t('Add Chart')}
          <Stack sx={{ alignItems: 'center', marginLeft: 'auto', flexDirection: 'row' }}>
            <Typography>{t('Compare')}</Typography>
            <Switch checked={isCompareChart} onClick={handleCompareChange} />
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <Grid container>
          <Grid item md={6} sm={12}>
            <Stack gap={2}>
              {graphData.graphType !== GraphTypes.PieChart &&
                graphData.graphType !== GraphTypes.RadialBarChart && (
                  <Stack>
                    <Typography style={labelStyle}>{t('X Axis')}</Typography>
                    <Select
                      value={graphData.xAxis}
                      onChange={(event) => handleFieldChange('xAxis')(event.target.value)}
                    >
                      <MenuItem disabled>{t('Select X Axis')}</MenuItem>
                      <MenuItem value="answerDate">{t('Time')}</MenuItem>
                      <MenuItem value="offsetDays" disabled={!userData?.user?.startDate}>
                        {t('Offset Days')}
                      </MenuItem>
                    </Select>
                  </Stack>
                )}
              <Stack direction="row">
                <EditableTextField
                  sx={{
                    width: isCompareChart ? '50%' : '100%',
                    borderBottom: '2px solid',
                    borderBottomColor: activeChart === 0 ? '#6C5DD3' : '#dadada',
                  }}
                  value={chartTitle}
                  setValue={(value) => setChartTitle(value)}
                  onClick={() => (isCompareChart ? setActiveChart(0) : undefined)}
                />
                {isCompareChart && (
                  <EditableTextField
                    sx={{
                      width: '50%',
                      borderBottom: '2px solid',
                      borderBottomColor: activeChart === 1 ? '#6C5DD3' : '#dadada',
                    }}
                    value={chartTitle1 ?? ''}
                    setValue={(value) => setChartTitle1(value)}
                    onClick={() => setActiveChart(1)}
                  />
                )}
              </Stack>
              {activeChart === 0 && (
                <>
                  <Stack flexDirection="row" gap={2}>
                    <Stack minWidth="48%">
                      <Typography style={labelStyle}>{t('Graph')}</Typography>
                      <Select
                        value={graphData.graphType}
                        onChange={(event) => handleFieldChange('graphType')(event.target.value)}
                      >
                        {Object.values(GraphTypes).map((graph) => {
                          if (
                            (isCompareChart ||
                              nonPieRaidalDataSources.has(graphData.meta?.dataSourceType || '')) &&
                            (graph === GraphTypes.PieChart || graph === GraphTypes.RadialBarChart)
                          ) {
                            return null; // Skip rendering the option
                          }
                          return (
                            <MenuItem
                              key={graph}
                              value={graph}
                              disabled={graph === GraphTypes.None}
                            >
                              {t(graph)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Stack>
                    <Stack minWidth="48%">
                      <Typography style={labelStyle}>{t('Data Source')}</Typography>
                      <DataSourceDropdown
                        dataSource={graphData.dataSource}
                        setDataSource={(source) => handleDataSourceChange('dataSource')(source)}
                        data={data}
                      />
                    </Stack>
                  </Stack>
                  {graphData.dataSource && graphData.graphType !== GraphTypes.None && (
                    <>
                      <Stack>
                        <Typography style={labelStyle}>{t('Plots')}</Typography>
                        {graphData.plots.map((plot) => (
                          <Plot
                            key={plot.id}
                            color={plot.color}
                            dataSource={graphData.dataSource}
                            setColor={(colorValue) =>
                              handlePlotColorChange('plots', plot.id, colorValue)
                            }
                            sourceValue={`${plot.sourceId}${plot.sourceValue}`}
                            setSourceValue={(sourceValue) =>
                              handlePlotSourceChange('plots', plot.id, sourceValue)
                            }
                            onDelete={() => handlePlotDelete('plots', plot.id)}
                          />
                        ))}
                        <Button
                          sx={{ alignSelf: 'flex-start' }}
                          onClick={() => addNewPlot('plots')}
                        >
                          {t('Add Plot +')}
                        </Button>
                      </Stack>
                      {graphData.graphType !== GraphTypes.PieChart &&
                        graphData.graphType !== GraphTypes.RadialBarChart && (
                          <Stack>
                            <Typography style={labelStyle}>{t('Y Axis')}</Typography>
                            <Select
                              value={graphData.yAxis}
                              onChange={(event) => handleFieldChange('yAxis')(event.target.value)}
                            >
                              <MenuItem disabled>{t('Select Y Axis')}</MenuItem>
                              {graphData.dataSource.questions &&
                                [
                                  ...new Set(
                                    graphData.dataSource.questions.flatMap((question) =>
                                      Array.isArray(question.meta?.units)
                                        ? question.meta.units
                                        : [question.meta?.units],
                                    ),
                                  ),
                                ].map((value, index) => (
                                  <MenuItem key={String(value) + String(index)} value={value}>
                                    {value}
                                  </MenuItem>
                                ))}
                              <MenuItem key="unit" value="unit">
                                {t('unit')}
                              </MenuItem>
                            </Select>
                          </Stack>
                        )}
                    </>
                  )}
                </>
              )}

              {activeChart === 1 && (
                <>
                  <Stack flexDirection="row" gap={2}>
                    <Stack minWidth="48%">
                      <Typography style={labelStyle}>{t('Graph')}</Typography>
                      <Select
                        value={graphData.graphType1}
                        onChange={(event) => handleFieldChange('graphType1')(event.target.value)}
                      >
                        {Object.values(GraphTypes).map((graph) => {
                          if (
                            isCompareChart &&
                            (graph === GraphTypes.PieChart || graph === GraphTypes.RadialBarChart)
                          ) {
                            return null; // Skip rendering the option
                          }
                          return (
                            <MenuItem
                              key={graph}
                              value={graph}
                              disabled={graph === GraphTypes.None}
                            >
                              {t(graph)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Stack>
                    <Stack minWidth="48%">
                      <Typography style={labelStyle}>{t('Data Source')}</Typography>
                      <DataSourceDropdown
                        dataSource={graphData.dataSource1}
                        setDataSource={(source) => handleDataSourceChange('dataSource1')(source)}
                        data={data}
                      />
                    </Stack>
                  </Stack>
                  {graphData.dataSource1 && graphData.graphType1 !== GraphTypes.None && (
                    <>
                      <Stack>
                        <Typography style={labelStyle}>{t('Plots')}</Typography>
                        {graphData.plots1?.map((plot) => (
                          <Plot
                            key={plot.id}
                            color={plot.color}
                            dataSource={graphData.dataSource1}
                            setColor={(colorValue) =>
                              handlePlotColorChange('plots1', plot.id, colorValue)
                            }
                            sourceValue={`${plot.sourceId}${plot.sourceValue}`}
                            setSourceValue={(sourceValue) =>
                              handlePlotSourceChange('plots1', plot.id, sourceValue)
                            }
                            onDelete={() => handlePlotDelete('plots1', plot.id)}
                          />
                        ))}
                        <Button
                          sx={{ alignSelf: 'flex-start' }}
                          onClick={() => addNewPlot('plots1')}
                        >
                          {t('Add Plot +')}
                        </Button>
                      </Stack>
                      <Stack>
                        <Typography style={labelStyle}>{t('Y Axis')}</Typography>
                        <Select
                          value={graphData.yAxis1}
                          onChange={(event) => handleFieldChange('yAxis1')(event.target.value)}
                        >
                          <MenuItem disabled>{t('Select Y Axis')}</MenuItem>
                          {graphData.dataSource1.questions &&
                            [
                              ...new Set(
                                graphData.dataSource1.questions.flatMap((question) =>
                                  Array.isArray(question.meta?.units)
                                    ? question.meta.units
                                    : [question.meta?.units],
                                ),
                              ),
                            ].map((value, index) => (
                              <MenuItem key={String(value) + String(index)} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          <MenuItem key="unit" value="unit">
                            {t('unit')}
                          </MenuItem>
                        </Select>
                      </Stack>
                    </>
                  )}
                </>
              )}

              {graphData.dataSource && graphData.graphType !== GraphTypes.None && (
                <Stack sx={{ backgroundColor: '#e8e8e8', padding: 2, borderRadius: 3 }}>
                  <Typography style={labelStyle}>{t('Category Assignment')}</Typography>
                  <Select
                    value={graphData.categoryId}
                    onChange={(event) => handleFieldChange('categoryId')(event.target.value)}
                    displayEmpty
                    renderValue={
                      graphData.categoryId === '' ? () => t('Select Category') : undefined
                    }
                  >
                    <MenuItem disabled>{t('Select Category Assignment')}</MenuItem>
                    {categoryList?.map((category) => (
                      <MenuItem key={category?.id} value={category?.id}>
                        {category?.title}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              )}
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography>{t('Display Fullwidth')}</Typography>
                <Switch
                  checked={graphData.fullWidth}
                  onClick={() => handleFieldChange('fullWidth')(!graphData.fullWidth)}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={6}>
            <PreviewChart
              type={graphData.graphType}
              type1={graphData.graphType1}
              plots={graphData.plots}
              xKey={graphData.xAxis}
              yKey={graphData.yAxis}
              plots1={graphData.plots1}
              yKey1={graphData.yAxis1}
              profileId={profileId}
              dataSource={graphData.dataSource}
              dataSource1={graphData.dataSource1}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isUpdateMode && (
          <Button
            sx={{ marginRight: 'auto' }}
            startIcon={
              deleteChartLoading ? <CircularProgress color="primary" size="1rem" /> : <Delete />
            }
            onClick={handleDelete}
            disabled={updateChartLoading || deleteChartLoading}
          >
            {t('Delete Chart')}
          </Button>
        )}
        <Button
          color="secondary"
          onClick={handleCloseModal}
          disabled={loading || updateChartLoading}
        >
          {t('Cancel')}
        </Button>
        <Button
          variant="contained"
          disabled={loading || updateChartLoading || deleteChartLoading}
          startIcon={
            (loading || updateChartLoading) && <CircularProgress color="primary" size="1rem" />
          }
          onClick={handleSave}
        >
          {t('Done')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
