import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, CircularProgress, Grid, Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { AssessmentQuestionEntity, useChartTabsQuery, useGetChartsQuery } from '../../../graphql';
import { GraphTypes } from '../../charts/ComposedChart';
import { ChartModal, GraphData } from './ChartModal';
import ChartsHeader from './ChartsHeader';
import CreateTabsDialogue from './CreateTabsDialogue';
import { PreviewChart } from './PreviewChart';

interface Properties {
  profileId: number;
}

const getTitle = (title: string | undefined, title1: string | undefined | null) => {
  if (title && !title1) {
    return title;
  }

  return `${title ?? ''} & ${title1 ?? ''}`;
};

const defaultData: GraphData = {
  id: '',
  dataSource: null,
  graphType: GraphTypes.None,
  plots: [],
  xAxis: 'answerDate',
  yAxis: '',
  categoryId: '',
  dataSource1: null,
  graphType1: GraphTypes.None,
  plots1: [],
  yAxis1: '',
  fullWidth: false,
};

type ModalState = {
  isUpdateMode: boolean;
  data: GraphData;
  open: boolean;
};

const defaultModalState: ModalState = {
  data: defaultData,
  isUpdateMode: false,
  open: false,
};

export const UserCharts = ({ profileId }: Properties) => {
  const { t } = usePreferredTranslation();
  const [chartModalState, setChartModalState] = useState<ModalState>(defaultModalState);
  const [activeTab, setActiveTab] = useState<string | undefined>();
  const [showChartTabsDialogue, setShowChartTabsDialogue] = useState(false);

  const {
    data: chartsData,
    loading,
    refetch: refetchCharts,
  } = useGetChartsQuery({
    fetchPolicy: 'network-only',
    variables: {
      categoryId: Number(activeTab),
      userId: profileId,
    },
    refetchWritePolicy: 'overwrite',
    skip: !activeTab,
  });

  const {
    data: chartTabs,
    refetch: refetchChartTabs,
    loading: chartTabsLoading,
  } = useChartTabsQuery({
    fetchPolicy: 'network-only',
    variables: {
      userId: String(profileId),
      chartCount: false,
    },
  });

  const handleModalClose = () => {
    setChartModalState(defaultModalState);
  };

  const handleModalSaveOrUpdate = () => {
    setChartModalState(defaultModalState);
    refetchCharts();
  };

  return (
    <Stack>
      <ChartsHeader
        chartTabLoading={chartTabsLoading}
        chartTabs={chartTabs?.chartTabs}
        onChartTabChange={(value) => setActiveTab(value)}
        onAddChart={() => setChartModalState((previous) => ({ ...previous, open: true }))}
        onAddChartTabs={() => setShowChartTabsDialogue(true)}
      />
      {loading ? (
        <Stack sx={{ alignItems: 'center', width: '100%', mt: 5 }}>
          <CircularProgress color="primary" />
        </Stack>
      ) : (
        <>
          {chartsData?.getCharts?.length === 0 ? (
            <Paper
              sx={{ position: 'relative', p: 2, height: 400, my: 3 }}
              data-testid="participant-chart-card"
            >
              <Stack alignItems="center" justifyContent="space-between" direction="row">
                <Typography variant="h6">{t('Trends')}</Typography>
              </Stack>
              <Stack sx={{ height: '100%' }} alignItems="center" justifyContent="center">
                {chartTabs?.chartTabs.length === 0 ? (
                  <Typography>
                    {t('Please Create Category first & Add a chart to begin.')}
                  </Typography>
                ) : (
                  <>
                    <Typography>{t('No trends have been added. Add a chart to begin.')}</Typography>
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      sx={{ mt: 2 }}
                      onClick={() =>
                        setChartModalState((previousState) => ({ ...previousState, open: true }))
                      }
                    >
                      {t('Add Chart')}
                    </Button>
                  </>
                )}
              </Stack>
            </Paper>
          ) : (
            <Grid container spacing={3} my={3}>
              {chartsData?.getCharts &&
                chartsData?.getCharts?.length > 0 &&
                chartsData?.getCharts?.map((chart) => (
                  <Grid
                    item
                    key={chart.id}
                    sx={{
                      width: '100%',
                      position: 'relative',
                      overflowX: 'hidden',
                    }}
                    sm={12}
                    md={chart.fullWidth ? 12 : 6}
                  >
                    <PreviewChart
                      profileId={profileId}
                      plots={JSON.parse(chart.plots as unknown as string)}
                      type={chart.type as GraphTypes}
                      xKey={chart.xAxis}
                      yKey={chart.yAxis}
                      isPreview={false}
                      plots1={chart.plots1 ? JSON.parse(chart.plots1 as unknown as string) : []}
                      yKey1={chart.yAxis1 as string}
                      type1={chart.type1 as GraphTypes}
                      chartTitle={getTitle(chart.title, chart.title1)}
                      dataSource={chart.dataSource as AssessmentQuestionEntity}
                      dataSource1={chart.dataSource1 as AssessmentQuestionEntity}
                      meta={chart.meta}
                    />

                    <Button
                      sx={{ position: 'absolute', right: 20, top: 40, color: 'GrayText' }}
                      startIcon={<SettingsIcon />}
                      onClick={() =>
                        setChartModalState({
                          isUpdateMode: true,
                          open: true,
                          data: {
                            categoryId: String(chart.categoryId),
                            dataSource: chart.dataSource as AssessmentQuestionEntity,
                            graphType: chart.type as GraphTypes,
                            id: chart.id,
                            plots: JSON.parse(chart.plots as unknown as string),
                            xAxis: chart.xAxis,
                            yAxis: chart.yAxis,
                            fullWidth: chart.fullWidth,
                            dataSource1: chart.dataSource1 as AssessmentQuestionEntity,
                            graphType1: chart.type1 as GraphTypes,
                            plots1: chart.plots1
                              ? JSON.parse(chart.plots1 as unknown as string)
                              : [],
                            yAxis1: chart.yAxis1 || '',
                            title: chart.title,
                            title1: chart.title1,
                            meta: chart.meta,
                          },
                        })
                      }
                    >
                      {t('Configure')}
                    </Button>
                  </Grid>
                ))}
            </Grid>
          )}
          {chartModalState.open && (
            <ChartModal
              profileId={profileId}
              open={chartModalState.open}
              handleClose={handleModalClose}
              handleSaveOrUpdate={handleModalSaveOrUpdate}
              chartData={chartModalState.data}
              isUpdateMode={chartModalState.isUpdateMode}
              categoryList={chartTabs?.chartTabs}
            />
          )}
          {showChartTabsDialogue && (
            <CreateTabsDialogue
              userId={profileId}
              open={showChartTabsDialogue}
              onClose={() => setShowChartTabsDialogue(false)}
              onRefetchTabs={refetchChartTabs}
            />
          )}
        </>
      )}
    </Stack>
  );
};
