import { ChangeEvent, useCallback, useState } from 'react';
import { Box, BoxProps, Fab, IconButton, TextField } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import CircularProgress from '@mui/material/CircularProgress';
import { theme } from '../../../../theme';
import { Attachment, AttachmentsActions, AttachmentsItems } from '../../attachments';
import { Emoji } from '../../Emojis';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties extends BoxProps {
  readonly onSubmitting?: (text: string, attachments: Attachment[]) => void;
  readonly onResetting?: () => void;
  readonly hasInput?: boolean;
  readonly hasAttacher?: boolean;
  readonly hasEmojis?: boolean;
  readonly hasVideoRecording?: boolean;
  readonly hasAudioRecording?: boolean;
  readonly hasPhotoRecording?: boolean;
  readonly hasSend?: boolean;
  readonly hasReset?: boolean;
  readonly loading?: boolean;
}

export const Controls = ({
  onSubmitting,
  onResetting,
  hasInput = true,
  hasAttacher = true,
  hasEmojis = true,
  hasVideoRecording = true,
  hasAudioRecording = true,
  hasPhotoRecording = true,
  hasSend = true,
  hasReset = true,
  loading = false,
  ...rest
}: Properties): JSX.Element => {
  const { t } = usePreferredTranslation();
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const [value, setValue] = useState('');

  const handleFilesSelect = useCallback(
    (files: File[]) => {
      setAttachments((attachments: Attachment[]) => [
        ...attachments,
        ...files.map(
          (file: File): Attachment => ({
            type: 'file',
            file,
          }),
        ),
      ]);
    },
    [setAttachments],
  );

  const handleEmojiSelect = useCallback(
    (emoji: Emoji) => {
      setValue((value) => value + emoji.native);
    },
    [setValue],
  );

  const handleAudioRecorded = useCallback(
    (blob: Blob, file: File, url: string) => {
      setAttachments((attachments: Attachment[]) => [
        ...attachments,
        {
          type: 'audio',
          blob,
          file,
          url,
        },
      ]);
    },
    [setAttachments],
  );

  const handleVideoRecorded = useCallback(
    (blob: Blob, file: File, url: string) => {
      setAttachments((attachments: Attachment[]) => [
        ...attachments,
        {
          type: 'video',
          blob,
          file,
          url,
        },
      ]);
    },
    [setAttachments],
  );

  const handlePhotoRecorded = useCallback(
    (blob: Blob, file: File, url: string) => {
      setAttachments((attachments: Attachment[]) => [
        ...attachments,
        {
          type: 'image',
          blob,
          file,
          url,
        },
      ]);
    },
    [setAttachments],
  );

  const handleAttachmentDelete = useCallback(
    (value: Attachment): void => {
      setAttachments((attachments: Attachment[]) =>
        attachments.filter((attachment: Attachment) => attachment !== value),
      );
    },
    [setAttachments],
  );

  const handleSendClick = useCallback(() => {
    onSubmitting?.(value, attachments);

    setValue('');
    setAttachments([]);
  }, [value, setValue, attachments, setAttachments]);

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  const handleSearchReset = useCallback(() => {
    onResetting?.();

    setValue('');
  }, [setValue]);

  return (
    <Box {...rest}>
      {attachments.length > 0 && (
        <AttachmentsItems
          sx={{
            border: '1px solid',
            borderLeft: 'none',
            borderTop: 'none',
            borderRight: 'none',
            overflowY: 'auto',
            height: 300,
            borderColor: (theme) => theme.palette.divider,
          }}
          onDelete={handleAttachmentDelete}
          attachments={attachments}
        />
      )}
      {hasInput && (
        <Box
          sx={{
            mt: 1.5,
            mx: 2,
          }}
          style={{
            maxHeight: '150px',
            overflow: 'auto',
          }}
        >
          <TextField
            color="secondary"
            size="small"
            variant="standard"
            fullWidth
            placeholder={t('Start typing here...')}
            disabled={loading}
            value={value}
            multiline
            onChange={handleSearchChange}
            onKeyPress={(event) => {
              if (['Enter', 'NumpadEnter'].includes(event.code) && !event.shiftKey) {
                handleSendClick();
              }
            }}
            InputProps={{
              disableUnderline: true,
              style: { fontSize: theme.typography.fontSize },
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          pl: 1,
          pr: 2,
          pt: 1,
          pb: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" alignItems="center">
          <AttachmentsActions
            display="inline-flex"
            hasAttacher={hasAttacher}
            hasEmojis={hasEmojis}
            hasAudioRecording={hasAudioRecording}
            hasVideoRecording={hasVideoRecording}
            hasPhotoRecording={hasPhotoRecording}
            onFilesSelect={handleFilesSelect}
            onEmojiSelect={handleEmojiSelect}
            onAudioRecorded={handleAudioRecorded}
            onVideoRecorded={handleVideoRecorded}
            onPhotoRecorded={handlePhotoRecorded}
          />

          {hasReset && value && (
            <IconButton aria-label="Reset search..." onClick={handleSearchReset}>
              <CancelRoundedIcon />
            </IconButton>
          )}
        </Box>
        <Box>
          {hasSend && (
            <Fab
              onClick={handleSendClick}
              disabled={loading || (value.trim().length === 0 && attachments.length === 0)}
              size="small"
              variant="circular"
              color="primary"
              aria-label="Send"
            >
              {loading ? <CircularProgress color="primary" size={16} /> : <ArrowUpwardIcon />}
            </Fab>
          )}
        </Box>
      </Box>
    </Box>
  );
};
