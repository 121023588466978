import { ChangeEvent, HTMLAttributes, ReactElement, useCallback, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogTitle, Typography } from '@mui/material';
import { useTheme, alpha, styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

import { ContentLoader, DialogCloseButton } from '../../atoms';
import { StudyEntity } from '../../../graphql';
import { useCurrentUser, useUser } from '../../../states';

interface Properties extends HTMLAttributes<ReactElement> {
  loading?: boolean;
  open?: boolean;
  studies: StudyEntity[];
  onClose?: () => void;
  onCancel?: () => void;
}

interface StudyOptionProperties {
  study?: StudyEntity;
  isSelected?: boolean;
  isOpenedLast?: boolean;
}

const StyledDialog = styled(Dialog)(() => ({
  '.MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container': {
    backgroundColor: 'rgba(255,255,255,1.0)',
  },
}));

const StudyOption = ({ study, isOpenedLast, isSelected }: StudyOptionProperties) => {
  const theme = useTheme();
  const { t } = usePreferredTranslation();

  return (
    <Stack direction="row" alignItems="center">
      <Stack flex={1} direction="row" alignItems="center">
        <Avatar
          sx={{
            background: isSelected ? theme.palette.primary.main : grey[300],
          }}
        />
        <Box sx={{ pl: 1.5 }}>
          <Typography variant="h6">{study?.name}</Typography>
          <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
            {study?.name}
          </Typography>
        </Box>
      </Stack>
      {isOpenedLast && (
        <Chip sx={{ borderRadius: 2, height: 24 }} label={t('Opened Last')} variant="outlined" />
      )}
    </Stack>
  );
};

export default function SelectStudyToLoginModal({
  loading,
  open = false,
  studies,
  onCancel,
  onClose,
}: Properties) {
  const { t } = usePreferredTranslation();
  const { studyId, setStudyId, setStudyArmId } = useUser();
  const theme = useTheme();
  const [selectedOption, setSelectedOption] = useState(studyId);
  const { refetch: refetchUser, loading: loadingCurrentUser } = useCurrentUser({});

  const handleClose = useCallback(() => onCancel && onCancel(), [onCancel]);

  const handleLoginWithSelectedStudy = useCallback(async () => {
    const selectedStudy = studies?.find(({ id }) => id === Number(selectedOption));

    if (selectedStudy) {
      await refetchUser();
      setStudyId(selectedStudy.id);
      if (selectedStudy.studyArms?.length) {
        setStudyArmId(Number(selectedStudy.studyArms?.[0]?.id));
      }
    }

    if (onClose) {
      onClose();
    }
  }, [onClose, selectedOption, setStudyId, studies, refetchUser, setStudyArmId]);

  const handleChangeOption = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(Number((event.target as HTMLInputElement).value));
  };

  return (
    <StyledDialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle>
        <Stack spacing={1}>
          <Box sx={{ mb: 2 }}>
            <Typography component="p" variant="h5">
              {t('Select a study to log in')}
            </Typography>
            <DialogCloseButton onClick={handleClose} />
          </Box>
          {loading ? (
            <ContentLoader
              speed={2}
              width={500}
              height={250}
              viewBox="0 0 500 250"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="3" ry="3" width="400" height="40" />
              <rect x="0" y="60" rx="3" ry="3" width="500" height="20" />

              <rect x="0" y="105" rx="0" ry="0" width="350" height="20" />
              <rect x="0" y="123" rx="0" ry="0" width="250" height="20" />
              <rect x="1" y="144" rx="0" ry="0" width="386" height="20" />

              <rect x="0" y="175" rx="0" ry="0" width="280" height="20" />
              <rect x="0" y="193" rx="0" ry="0" width="129" height="20" />
              <rect x="1" y="214" rx="0" ry="0" width="386" height="20" />
            </ContentLoader>
          ) : (
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue="top"
                value={selectedOption}
                onChange={handleChangeOption}
              >
                {studies?.map((study, itemIndex) => (
                  <FormControlLabel
                    key={itemIndex}
                    value={study.id}
                    label={
                      <StudyOption
                        study={study}
                        isSelected={study.id === selectedOption}
                        isOpenedLast={studyId === study.id}
                      />
                    }
                    control={<Radio sx={{ color: grey[300] }} />}
                    labelPlacement="start"
                    sx={{
                      p: 2,
                      m: 0,
                      borderRadius: theme.typography.pxToRem(12),
                      background:
                        study.id === selectedOption
                          ? alpha(theme.palette.primary.main, 0.12)
                          : theme.palette.common.white,
                      '& .MuiFormControlLabel-label': {
                        flex: 1,
                      },
                    }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}

          <Stack alignItems="flex-end">
            <Stack direction="row" spacing={2} sx={{ mt: 3, mb: 1 }}>
              <Button variant="outlined" onClick={handleClose}>
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                startIcon={loadingCurrentUser && <CircularProgress color="primary" size="1rem" />}
                onClick={handleLoginWithSelectedStudy}
                disabled={!selectedOption || loadingCurrentUser}
              >
                {t('Sign in to selected study')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </DialogTitle>
    </StyledDialog>
  );
}
