import 'react-calendar/dist/Calendar.css';

import { CircularProgress, Dialog, Slide } from '@mui/material';
import { HTMLAttributes, ReactElement, forwardRef, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { TransitionProps } from '@mui/material/transitions';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  useCreateAssessmentResponseMutation,
  useUpdateAssessmentResponseMutation,
} from '../../../graphql';

import InputStep from './InputStep';
import QuestionNavigator from './QuestionNavigator';
import SplashStep from './SplashStep';
import { sortQuestions } from '../../organisms/FormBuilder/utils';
import { useCurrentUser } from '../../../states';
import { useJournal } from '../../../states/journal/useJournal';
import { getDateStringFromDateTime } from '../../../utils';
import { useActiveJournal } from '../../../states/journal/useActiveJournal';
import { ActiveStep } from '../../../states/journal/useActiveJournal/types';

const SCROLLABLE_QUESTIONS = new Set(['Symptoms', 'Pain', 'Mealtime']);
interface Properties extends HTMLAttributes<ReactElement> {
  open?: boolean;
  userId?: number;
  onSubmit?: () => void;
}

const Transition = forwardRef(function Transition(
  properties: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  reference: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={reference} {...properties} />;
});

export default function JournalModal({ open = false, onSubmit, userId }: Properties) {
  const sm = useMediaQuery('(min-width:750px)');
  const currentUser = useCurrentUser();

  const {
    activeJournal: journal,
    activeDate: currentDate,
    closeJournalModal,
    activeStep,
    questionIndex,
    setQuestionIndex,
    setActiveStepToSplash,
    isEditing,
    editingResponseId,
    closeEditing,
  } = useActiveJournal();

  const [createAssessmentResponse] = useCreateAssessmentResponseMutation();
  const [updateAssessmentResponse] = useUpdateAssessmentResponseMutation();
  const [answeredQuestionIds, setAnsweredQuestionIds] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [resistanceAnswers, setResistanceAnswers] = useState<IResistanceIntakeAnswers[]>([]);
  const [showResistanceAnswerPreview, setShowResistanceAnswerPreview] = useState(false);
  const sortedQuestions = sortQuestions({ questions: journal?.questions }).questions;
  const question = sortedQuestions?.length ? sortedQuestions[questionIndex] : null;

  const {
    answerToQuestions,
    loading: loadingAnswers,
    refetch: refetchAnswers,
    questionAnswersMap,
  } = useJournal({
    userId: Number(userId ?? currentUser.data?.user?.id),
    libraryId: Number(journal?.id) || 0,
    answerDate: getDateStringFromDateTime(currentDate) as unknown as Date,
    skip: !journal,
    questions: sortedQuestions,
  });

  const numberOfAnsweredQuestions =
    Number(answeredQuestionIds.length) +
    Number(answerToQuestions.filter((a: any) => a.isAnswered).length);

  const isLastQuestion = numberOfAnsweredQuestions === Number(sortedQuestions?.length) - 1;

  const handleSubmit = useCallback(
    async (answers?: IQuestionAnswer) => {
      if (
        (question?.type === 'question' &&
          questionIndex >= (question?.questions?.length || 0) - 1) ||
        SCROLLABLE_QUESTIONS.has(question?.label || '') ||
        question?.meta?.scrollableList
      ) {
        const assessmentResponse = {
          library: Number(journal?.id) || 0,
          user: Number(userId ?? currentUser.data?.user?.id),
          answerDate: getDateStringFromDateTime(currentDate) as unknown as Date,
          answers:
            answers?.values.map((v) => {
              if (typeof v === 'string') {
                return {
                  questionId: Number(answers.questionId),
                  values: [v],
                  comment: '',
                };
              }

              const values = v.values.filter(Boolean);

              return {
                questionId: Number(v.questionId),
                values: values.map((value) =>
                  typeof value === 'object' ? JSON.stringify(value) : value.toString(),
                ),
                comment: '',
              };
            }) || [],
        };

        setLoading(true);

        await (isEditing
          ? updateAssessmentResponse({
              variables: {
                assessmentResponse,
                id: Number(editingResponseId),
              },
            })
          : createAssessmentResponse({
              variables: {
                assessmentResponse,
              },
            }));

        setAnsweredQuestionIds([...answeredQuestionIds, question?.id || '']);
        setLoading(false);
        const nextUnansweredQuestionIndex = sortedQuestions?.findIndex(
          (q, index) =>
            q.id &&
            q.id !== question?.id &&
            index > questionIndex &&
            !answeredQuestionIds.includes(q.id) &&
            !answerToQuestions.find((a: any) => a.id === q.id)?.isAnswered,
        );
        if (
          nextUnansweredQuestionIndex !== undefined &&
          nextUnansweredQuestionIndex !== questionIndex &&
          question?.meta?.moveToNextOnSubmit &&
          questionIndex < (sortedQuestions?.length || 0) - 1 &&
          nextUnansweredQuestionIndex >= 0
        ) {
          setQuestionIndex(nextUnansweredQuestionIndex);
          closeEditing();
        } else {
          setActiveStepToSplash();
          onSubmit?.();
        }
      } else {
        const nextUnansweredQuestionIndex = sortedQuestions?.findIndex(
          (q) =>
            q.id &&
            q.id !== question?.id &&
            !answeredQuestionIds.includes(q.id) &&
            !answerToQuestions.find((a: any) => a.id === q.id)?.isAnswered,
        );

        if (nextUnansweredQuestionIndex !== undefined && nextUnansweredQuestionIndex >= 0) {
          setQuestionIndex(nextUnansweredQuestionIndex);
          closeEditing();
        } else setActiveStepToSplash();
      }
    },
    [
      question,
      answeredQuestionIds,
      answerToQuestions,
      questionIndex,
      sortedQuestions,
      createAssessmentResponse,
      currentUser.data?.user?.id,
      onSubmit,
      journal,
      currentDate,
      setActiveStepToSplash,
      setQuestionIndex,
      isEditing,
      updateAssessmentResponse,
      editingResponseId,
      closeEditing,
      userId,
    ],
  );

  const findQuestion = useCallback((questions: any, questionId: string) => {
    for (const object of questions || []) {
      if (object.id === questionId) {
        return object; // Found the object with the target ID
      }
      if (object.questions && object.questions.length > 0) {
        const foundInChildren: any = findQuestion(object.questions, questionId);
        if (foundInChildren) {
          return foundInChildren; // Found the object in the children array
        }
      }
    }
    return null; // Object with the target ID not found
  }, []);

  const handleSubmitResistance = useCallback(
    async (answers?: IResistanceIntakeAnswers[]) => {
      if (!answers?.length) return;

      const assessmentResponse = {
        library: Number(journal?.id) || 0,
        user: Number(userId ?? currentUser.data?.user?.id),
        answerDate: getDateStringFromDateTime(currentDate) as unknown as Date,
        answers:
          answers?.map((v) => {
            return {
              ...v,
              questionId: Number(v.questionId),
              comment: '',
            };
          }) || [],
      };

      setLoading(true);
      await (isEditing
        ? updateAssessmentResponse({
            variables: {
              id: Number(editingResponseId),
              assessmentResponse,
            },
          })
        : createAssessmentResponse({
            variables: {
              assessmentResponse,
            },
          }));
      setResistanceAnswers(
        answers?.map((ele) => {
          const currentQuestion = findQuestion(sortedQuestions, ele.questionId);
          return {
            ...ele,
            questionId: currentQuestion?.id || currentQuestion?.label || null,
          };
        }) || [],
      );
      setShowResistanceAnswerPreview(true);
      setLoading(false);
    },
    [
      createAssessmentResponse,
      currentDate,
      currentUser.data?.user?.id,
      journal?.id,
      sortedQuestions,
      findQuestion,
      isEditing,
      updateAssessmentResponse,
      editingResponseId,
      userId,
    ],
  );

  const handleSubmitCardio = useCallback(
    async (answer?: ICardioIntakeAnswer) => {
      if (!answer) {
        return;
      }

      setLoading(true);

      const assessmentResponse = {
        library: Number(journal?.id) || 0,
        user: Number(userId ?? currentUser.data?.user?.id),
        answerDate: getDateStringFromDateTime(currentDate) as unknown as Date,
        answers: [
          {
            ...answer,
            questionId: Number(answer.questionId),
            comment: '',
          },
        ],
      };

      await (isEditing
        ? updateAssessmentResponse({
            variables: {
              id: Number(editingResponseId),
              assessmentResponse,
            },
          })
        : createAssessmentResponse({
            variables: {
              assessmentResponse,
            },
          }));

      setLoading(false);
      setActiveStepToSplash();
      onSubmit?.();
    },
    [
      createAssessmentResponse,
      currentDate,
      currentUser.data?.user?.id,
      journal?.id,
      onSubmit,
      setActiveStepToSplash,
      editingResponseId,
      isEditing,
      updateAssessmentResponse,
      userId,
    ],
  );

  const handlePrevious = useCallback(() => {
    if (question?.meta?.moveToNextOnSubmit) {
      const previousUnansweredQuestionIndex = sortedQuestions
        ?.slice(0, questionIndex)
        ?.findLastIndex((q) => {
          return (
            q.id &&
            q.id !== question?.id &&
            !answeredQuestionIds.includes(q.id) &&
            !answerToQuestions.find((a: any) => a.id === q.id)?.isAnswered
          );
        });
      if (
        previousUnansweredQuestionIndex !== undefined &&
        previousUnansweredQuestionIndex !== questionIndex &&
        questionIndex > 0 &&
        previousUnansweredQuestionIndex >= 0
      ) {
        setQuestionIndex(previousUnansweredQuestionIndex);
      }
    }
    setActiveStepToSplash();
  }, [
    questionIndex,
    answerToQuestions,
    answeredQuestionIds,
    sortedQuestions,
    question?.id,
    setQuestionIndex,
    question?.meta?.moveToNextOnSubmit,
    setActiveStepToSplash,
  ]);

  const onFinishResistanceAnswerPreview = useCallback(() => {
    setShowResistanceAnswerPreview(false);
    setActiveStepToSplash();
    onSubmit?.();
  }, [onSubmit, setActiveStepToSplash]);

  useEffect(() => {
    if (activeStep === ActiveStep.Splash) {
      refetchAnswers();
    }
  }, [activeStep, refetchAnswers]);

  return (
    <Dialog
      fullWidth
      fullScreen={!sm || activeStep === ActiveStep.Question}
      maxWidth="md"
      open={open}
      onClose={closeJournalModal}
      TransitionComponent={Transition}
      transitionDuration={350}
      PaperProps={{
        sx: {
          borderRadius: sm ? 2 : 0,
        },
      }}
    >
      {activeStep === ActiveStep.Splash && (
        <SplashStep
          questions={sortedQuestions}
          answers={answerToQuestions}
          loading={loadingAnswers}
        />
      )}

      {activeStep === ActiveStep.Question && question && (
        <Box key={question.id}>
          {question.type === 'question' ? (
            <QuestionNavigator
              key={question.id}
              id={question.id || ''}
              progressBarValue={
                sortedQuestions ? (numberOfAnsweredQuestions / sortedQuestions.length) * 100 : 0
              }
              title={question.label || ''}
              scrollableList={
                SCROLLABLE_QUESTIONS.has(question.label || '') || question?.meta?.scrollableList
              }
              questions={sortQuestions(question).questions}
              resistanceAnswers={resistanceAnswers}
              showResistanceAnswerPreview={showResistanceAnswerPreview}
              onPrev={handlePrevious}
              onSubmit={handleSubmit}
              moveToNextOnSubmit={question?.meta?.moveToNextOnSubmit && !isLastQuestion}
              onSubmitResistance={handleSubmitResistance}
              onSubmitCardio={handleSubmitCardio}
              onFinishResistanceAnswerPreview={onFinishResistanceAnswerPreview}
              questionAnswersMap={questionAnswersMap}
            />
          ) : (
            <InputStep
              key={question.id}
              title={question.label || ''}
              questions={question.questions}
              onPrev={handlePrevious}
              onSubmit={handleSubmit}
              onClose={closeJournalModal}
            />
          )}
        </Box>
      )}
      {(loading || loadingAnswers) && (
        <CircularProgress
          color="primary"
          sx={{
            position: 'fixed',
            top: '45%',
            zIndex: 1000,
            alignSelf: 'center',
          }}
        />
      )}
    </Dialog>
  );
}
