import { Box, BoxProps, Chip, Menu, MenuItem, Typography } from '@mui/material';
import { MouseEvent, useCallback, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import GroupIcon from '@mui/icons-material/Group';
import { alpha } from '@mui/material/styles';
import { dayjs } from '../../../../utils/dayjs';
import { UserEntity, useUsersQuery } from '../../../../graphql';

import { Avatar } from '../../../atoms';
import { FORMATS } from '../../../../constants';
import { UserStatus } from '../../../../constants/users';
import { theme } from '../../../../theme';
import { useUser } from '../../../../states';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties extends BoxProps {
  readonly onSelectUser: (user: UserEntity | null) => void;
  readonly selectedUser: UserEntity | null;
}

export const Users = ({ onSelectUser, selectedUser, sx, children, ...rest }: Properties) => {
  const { studyId } = useUser();
  const { t } = usePreferredTranslation();
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const usersQuery = useUsersQuery({
    variables: {
      search: '',
      studyId,
      status: UserStatus.VERIFIED,
      sortByLatestMessages: true,
    },
  });

  const users = usersQuery.data?.users.items || [];
  const count = users.length;

  const handleItemClick = useCallback(
    (user: UserEntity) => () => {
      onSelectUser(user);
      setAnchor(null);
    },
    [onSelectUser, setAnchor],
  );

  const handleMenuClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchor(event.currentTarget);
    },
    [setAnchor],
  );

  const handleMenuClose = useCallback(() => {
    setAnchor(null);
  }, [setAnchor]);

  const render = useCallback(
    (user: UserEntity) => (
      <Box
        sx={{
          p: 2,
          gap: 1.5,
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          minWidth: 0,
        }}
      >
        <Avatar size={40} name={user.name} />
        <Box sx={{ flexGrow: 1, minWidth: 0 }}>
          <Box
            color={theme.palette.text.primary}
            sx={{ display: 'flex', fontWeight: '600', alignItems: 'center', pr: 3 }}
          >
            {user.name}
          </Box>
          <Typography variant="body2" color={theme.palette.secondary.main}>
            {dayjs(user.createdAt).format(FORMATS.DATE)}
          </Typography>
        </Box>
      </Box>
    ),
    [],
  );

  return count === 0 ? null : (
    <Box sx={{ width: '100%', ...sx }} {...rest}>
      <Box
        sx={{
          border: `4px dotted ${theme.palette.primary.main}`,
        }}
        onClick={handleMenuClick}
      >
        {selectedUser ? (
          render(selectedUser)
        ) : (
          <Box
            sx={{
              p: 2,
              gap: 1.5,
              display: 'flex',
              flexGrow: 1,
              alignItems: 'center',
              minWidth: 0,
            }}
          >
            <Avatar size={40}>
              <GroupIcon />
            </Avatar>
            <Box sx={{ flexGrow: 1, minWidth: 0 }}>
              <Box
                color={theme.palette.text.primary}
                sx={{ display: 'flex', fontWeight: '600', alignItems: 'center', pr: 3 }}
              >
                {t('Select user')}
              </Box>
            </Box>
            <Box>
              {usersQuery.loading ? (
                <CircularProgress color="primary" size={16} />
              ) : (
                <Chip size="small" label={count} color="primary" />
              )}
            </Box>
          </Box>
        )}
      </Box>

      <Menu
        autoFocus={false}
        id="users-menu-button"
        aria-labelledby="users-menu-button"
        disableEnforceFocus
        disableAutoFocus
        disableRestoreFocus
        anchorEl={anchor}
        open={!!anchor}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {users.map((user) => (
          <MenuItem
            sx={{
              p: 0,
              m: 0,
              background:
                user.id === selectedUser?.id
                  ? alpha(theme.palette.primary.main, 0.12)
                  : theme.palette.common.white,
            }}
            key={user.id}
            onClick={handleItemClick(user)}
          >
            {render(user)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
