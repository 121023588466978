import { createTheme } from '@mui/material';

// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme({
  palette: {
    primary: {
      light: '#f47321',
      main: '#f47321',
      dark: '#f47321',
      contrastText: '#fff',
    },
    secondary: {
      main: '#616175',
    },
    text: {
      primary: '#0E0E19',
      secondary: '#616175',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '.MuiListItemButton-root': {
            height: 40,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          },
        },
      },
    },
  },
});

export { theme };
