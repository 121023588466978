import { useState, ReactElement, useEffect, useRef, useCallback } from 'react';
import { Box, Button, Divider, Stack, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { IQuestion } from '../../../types/intervention';
import { EditableQuestion } from '../EditableQuestion';
import { theme } from '../../../theme';
import { downloadJSON } from '../../../utils';
import { QuestionCategoryEntity } from '../../../graphql';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

export interface IFormBuilder {
  title: string;
  description: string;
  questions: IQuestion[];
}
interface FormBuilderProperties {
  categories?: QuestionCategoryEntity[];
  primaryButtonText?: string;
  shouldAskDescription?: boolean;
  showImportExportOptions?: boolean;
  showQuestionUpdateButton?: boolean;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: (formData: IFormBuilder) => void;
  onSecondaryButtonClick?: () => void;
}

const defaulftForm = {
  title: '',
  description: '',
  questions: [],
};

const defaultQuestion = {
  type: '',
  category: {
    id: '',
    name: '',
  },
  label: '',
  identifier: '',
};

export function FormBuilder({
  categories,
  primaryButtonText,
  onPrimaryButtonClick,
  secondaryButtonText,
  onSecondaryButtonClick,
  showImportExportOptions,
  shouldAskDescription,
  showQuestionUpdateButton = true,
}: FormBuilderProperties): ReactElement {
  const { t } = usePreferredTranslation();
  const [form, setForm] = useState<IFormBuilder>(defaulftForm);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [questions, setQuestions] = useState<IQuestion[]>([]);

  const inputReference = useRef<HTMLInputElement>(null);

  const handleAddQuestion = useCallback(() => {
    setQuestions([...questions, defaultQuestion]);
  }, [questions]);

  useEffect(() => {
    if (questions.length === 0) {
      handleAddQuestion();
    }
  }, [handleAddQuestion, questions.length]);

  const handleDeleteQuestion = (index: number) => {
    setQuestions(questions.filter((_item, index_) => index !== index_));
  };

  const handleUpdateQuestion = (question: IQuestion, index: number) => {
    setQuestions(
      questions.map((item, index_) =>
        index === index_
          ? {
              ...item, // Keep existing properties
              ...question, // Apply updates
              meta: {
                // Merge meta objects
                ...item.meta, // Existing meta properties
                ...question.meta, // New meta updates
              },
            }
          : item,
      ),
    );
  };

  const renderQuestion = (question: IQuestion, index: number): ReactElement | null => {
    return (
      <EditableQuestion
        key={index}
        {...question}
        meta={question.meta || {}}
        categories={categories || []}
        showUpdateButton={showQuestionUpdateButton}
        onUpdateQuestion={(que) => handleUpdateQuestion(que, index)}
        onDelete={() => handleDeleteQuestion(index)}
        showDelete
      />
    );
  };

  useEffect(() => {
    setTitle(form.title || '');
    setDescription(form.description || '');
    setQuestions(form.questions || []);
  }, [form]);

  const handleUpload = (event_: any) => {
    const fileReader = new FileReader();
    const file = event_.target.files[0];
    if (!file) {
      return;
    }
    fileReader.readAsText(file, 'utf8');
    fileReader.addEventListener('load', (event) => {
      try {
        const output = event.target?.result;
        if (typeof output === 'string') {
          setForm(JSON.parse(output));
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    });
  };

  const handleExport = () => {
    const formData = {
      title,
      description,
      questions,
    };
    downloadJSON(formData, title);
  };

  return (
    <Box>
      {showImportExportOptions && (
        <Box>
          <Button
            variant="contained"
            onClick={() => inputReference?.current && inputReference.current.click()}
          >
            {t('Import a form')}
          </Button>
          <input
            type="file"
            style={{ display: 'none' }}
            ref={inputReference}
            onChange={handleUpload}
          />

          <Button variant="outlined" onClick={handleExport} sx={{ ml: 2 }}>
            {t('Export form')}
          </Button>

          <Divider sx={{ my: 3 }} />
        </Box>
      )}

      {shouldAskDescription && (
        <TextField
          InputProps={{
            disableUnderline: true,
            sx: { fontSize: theme.typography.h6.fontSize },
          }}
          variant="standard"
          fullWidth
          placeholder={t('Module Description')}
          value={description}
          onChange={(event_) => setDescription(event_.target.value)}
          sx={{ mb: 2 }}
        />
      )}

      {questions.map((question, index) => renderQuestion(question, index))}

      <Button
        fullWidth
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => handleAddQuestion()}
      >
        {t('Add a question')}
      </Button>

      <Stack alignItems="flex-end">
        <Stack direction="row" spacing={2} sx={{ mt: 3, mb: 1 }}>
          <Button variant="outlined" onClick={onSecondaryButtonClick}>
            {secondaryButtonText}
          </Button>
          {primaryButtonText && onPrimaryButtonClick && (
            <Button
              onClick={() => onPrimaryButtonClick({ title, description, questions })}
              variant="contained"
            >
              {primaryButtonText}
            </Button>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
