import { Box, FormGroup, Typography, Slider, Grid, TextField, InputAdornment } from '@mui/material';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { useEffect, useState, useRef } from 'react';

interface SliderFieldProperties {
  index?: number;
  label?: string;
  value?: string;
  required?: boolean;
  showBoldLabel?: boolean;
  hideLabel?: boolean;
  onChange: (value: string) => void;
  min?: number;
  max?: number;
  step?: number;
}

export const SliderField = ({
  index,
  label,
  required,
  value,
  showBoldLabel,
  hideLabel = false,
  onChange,
  min = 0,
  max = 10,
  step = 1,
}: SliderFieldProperties) => {
  const initializedReference = useRef(false);
  const [localValue, setLocalValue] = useState<number>(() => {
    const initialValue = value ? Number(value) : min;
    return Number.isNaN(initialValue) ? min : Math.min(Math.max(initialValue, min), max);
  });

  useEffect(() => {
    if (!initializedReference.current) {
      initializedReference.current = true;
      return;
    }

    let parsedValue = value ? Number(value) : min;
    parsedValue = Number.isNaN(parsedValue) ? min : Math.min(Math.max(parsedValue, min), max);

    setLocalValue(parsedValue);

    if (onChange && (parsedValue < min || parsedValue > max)) {
      onChange(String(parsedValue));
    }
  }, [value, min, max, onChange]);

  const handleSliderChange = (_: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setLocalValue(newValue);
      onChange(`${newValue}`);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue === '') {
      setLocalValue(min);
      onChange(`${min}`);
      return;
    }

    const newValue = Number(inputValue);
    if (!Number.isNaN(newValue)) {
      const clampedValue = Math.min(Math.max(newValue, min), max);
      setLocalValue(clampedValue);
      onChange(`${clampedValue}`);
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <FormGroup>
        {!hideLabel && (
          <Typography variant={showBoldLabel ? 'h5' : 'body2'}>
            {index}
            {index && '.'} {label} {required && '*'}
          </Typography>
        )}
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <SentimentVeryDissatisfiedIcon color="error" />
            </Grid>
            <Grid item xs>
              <Slider
                min={min}
                step={step}
                max={max}
                value={localValue}
                onChange={handleSliderChange}
                aria-labelledby="input-slider"
                marks
                valueLabelDisplay="auto"
              />
            </Grid>
            <Grid item>
              <SentimentSatisfiedAltIcon color="success" />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <TextField
                fullWidth
                value={localValue}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="caption" color="textSecondary">
                        {min}-{max}
                      </Typography>
                    </InputAdornment>
                  ),
                  inputProps: { step, min, max, type: 'number', 'aria-labelledby': 'input-slider' },
                }}
                size="small"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>
      </FormGroup>
    </Box>
  );
};
