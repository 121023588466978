import { ReactElement, useCallback } from 'react';
import { Box, BoxProps } from '@mui/material';

import { Attacher } from '../../Attacher';
import { Emoji, Emojis } from '../../Emojis';
import { AudioRecord, PhotoRecord, VideoRecord } from '../../recordings';

interface Properties extends BoxProps {
  readonly disabled?: boolean;
  readonly hasAttacher?: boolean;
  readonly hasEmojis?: boolean;
  readonly hasVideoRecording?: boolean;
  readonly hasAudioRecording?: boolean;
  readonly hasPhotoRecording?: boolean;
  readonly onFilesSelect?: (files: File[]) => void;
  readonly onEmojiSelect?: (emoji: Emoji) => void;
  readonly onAudioRecorded?: (blob: Blob, file: File, url: string) => void;
  readonly onVideoRecorded?: (blob: Blob, file: File, url: string) => void;
  readonly onPhotoRecorded?: (blob: Blob, file: File, url: string) => void;
}

export function AttachmentsActions({
  disabled = false,
  hasAttacher = true,
  hasEmojis = true,
  hasVideoRecording = true,
  hasAudioRecording = true,
  hasPhotoRecording = true,
  onFilesSelect,
  onEmojiSelect,
  onAudioRecorded,
  onVideoRecorded,
  onPhotoRecorded,
  children,
  ...rest
}: Properties): ReactElement<Properties> {
  const handleFilesSelect = useCallback(
    (files: File[]) => {
      onFilesSelect?.(files);
    },
    [onFilesSelect],
  );

  const handleEmojiSelect = useCallback(
    (emoji: Emoji) => {
      onEmojiSelect?.(emoji);
    },
    [onEmojiSelect],
  );

  const handleAudioRecordingComplete = useCallback(
    (blob: Blob, file: File, url: string) => {
      onAudioRecorded?.(blob, file, url);
    },
    [onAudioRecorded],
  );

  const handleVideoRecordingComplete = useCallback(
    (blob: Blob, file: File, url: string) => {
      onVideoRecorded?.(blob, file, url);
    },
    [onVideoRecorded],
  );

  const handlePhotoRecordingComplete = useCallback(
    (blob: Blob, file: File, url: string) => {
      onPhotoRecorded?.(blob, file, url);
    },
    [onPhotoRecorded],
  );

  return (
    <Box data-testid="attachmentsActionsComponent" {...rest}>
      {hasAttacher && <Attacher onFilesSelect={handleFilesSelect} disabled={disabled} />}
      {hasEmojis && <Emojis onEmojiSelect={handleEmojiSelect} disabled={disabled} />}
      {/* {hasAudioRecording && (
        <AudioRecord onComplete={handleAudioRecordingComplete} disabled={disabled} />
      )} */}
      {/* {hasVideoRecording && (
        <VideoRecord onComplete={handleVideoRecordingComplete} disabled={disabled} />
      )} */}
      {hasPhotoRecording && (
        <PhotoRecord onComplete={handlePhotoRecordingComplete} disabled={disabled} />
      )}
    </Box>
  );
}
