import { AppBar, CircularProgress, IconButton, useMediaQuery } from '@mui/material';
import { HTMLAttributes, ReactElement, useCallback, useMemo } from 'react';

import Avatar from '@mui/material/Avatar';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import PermPhoneMsgOutlinedIcon from '@mui/icons-material/PermPhoneMsgOutlined';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import styled from 'styled-components';
import { PageHeading } from '..';
import { theme } from '../../theme';
import { useCurrentUser, useUser } from '../../states';
import { UserRoles } from '../../constants/users';
import { usePreferredTranslation } from '../../hooks/usePreferredTranslation';

const StyledAvatar = styled(Avatar)`
  background-color: #e2dff6;
  color: ${theme.palette.primary.main};
  font-size: 16px;
  font-weight: 600;
`;

const StyledIconButton = styled(IconButton)`
  background-color: #e2dff6;
  color: ${theme.palette.primary.main};
  width: 40px;
  height: 40px;

  svg {
    font-size: 20px;
  }
`;

const StyledTabs = styled(Tabs)`
  .Mui-selected {
    font-weight: 600;
  }
  .MuiTabs-indicator {
    background-color: ${theme.palette.primary.main};
  }
`;

interface Properties extends HTMLAttributes<ReactElement> {
  currentTabIndex?: any;
  user?: any;
  loading?: boolean;
  onTabChange?: (_event: any, index: any) => void;
  onSelectCallDevice?: () => void;
  onSelectMessageDevice?: () => void;
  onVideoCall?: () => void;
  refetch?: () => Promise<void>;
}

export default function ParticipantProfileAppBarSkinny({
  currentTabIndex,
  user,
  loading,
  onTabChange,
  onSelectCallDevice,
  onSelectMessageDevice,
  onVideoCall,
  refetch,
}: Properties) {
  const { t } = usePreferredTranslation();
  const currentUser = useCurrentUser();
  const { checkUserRole } = useUser();
  const sm = useMediaQuery('(min-width:750px)');
  const tabsList = [
    { label: t('Summary'), id: 0 },
    { label: t('Charts'), id: 5 },
    // { label: t('Notes and Reports'), id: 1 },
    { label: t('Coaching'), id: 2 },
    { label: t('Data Collection'), id: 3 },
    { label: t('Audit Trail'), id: 4 },
    { label: t('Integrations'), id: 6 },
  ];

  const getShortName = useCallback(() => {
    return user?.name?.split(' ').map((string_: string) => string_[0]);
  }, [user]);

  const isAdminAndNotCurrentUser = useMemo(() => {
    if (!currentUser) {
      return false;
    }

    const currentUserData = currentUser.data?.user;
    return (
      !(currentUserData?.id === user.id) &&
      (checkUserRole(currentUserData, UserRoles.ADMINISTRATOR) ||
        checkUserRole(currentUserData, UserRoles.SUPER_ADMINISTRATOR))
    );
  }, [checkUserRole, currentUser, user.id]);

  const getSubtitleText = useCallback(() => {
    const sexualOrientation = user?.sexualOrientation ? `${user?.sexualOrientation}, ` : '';
    const age = user?.age ? `${user?.age} • ` : '';
    const studies = user?.studies?.map((study: any) => study.name);
    const timezone = user?.timezone ? ` • ${user?.timezone}` : '';
    return `${sexualOrientation} ${age} ${studies} ${timezone}`;
  }, [user]);

  return (
    <AppBar
      // 239 is the width of side bar
      sx={{
        px: 4,
        pt: 2,
        mb: 3,
        maxWidth: sm ? 'calc(100vw - 239px)' : 'unset',
        overflowX: 'auto',
      }}
      position="sticky"
      color="inherit"
    >
      <PageHeading
        loading={loading}
        title={user?.name}
        titleSx={{ whiteSpace: 'nowrap' }}
        subtitle={getSubtitleText()}
        status={user?.status}
        idNumber={user?.idNumber}
        avatar={<StyledAvatar sx={{ width: 40, height: 40 }}>{getShortName()}</StyledAvatar>}
        showSubscription={isAdminAndNotCurrentUser}
        userId={user?.id}
        isSubscribed={user.isSubscribed}
        notificationPreferenceType={user.notificationPreferenceType}
        refetch={refetch}
      >
        <Stack direction="row" spacing={2}>
          <StyledIconButton
            disabled={loading}
            aria-label="audio call"
            data-testid="audio-call"
            size="large"
            onClick={onSelectCallDevice}
          >
            {loading ? (
              <CircularProgress color="primary" size="1.4rem" />
            ) : (
              <PermPhoneMsgOutlinedIcon />
            )}
          </StyledIconButton>
          <StyledIconButton
            disabled={loading}
            aria-label="video call"
            data-testid="video-call"
            size="large"
            onClick={onVideoCall}
          >
            {loading ? (
              <CircularProgress color="primary" size="1.4rem" />
            ) : (
              <VideocamOutlinedIcon />
            )}
          </StyledIconButton>
          <StyledIconButton
            disabled={loading}
            aria-label="message"
            data-testid="message"
            size="large"
            onClick={onSelectMessageDevice}
          >
            {loading ? <CircularProgress color="primary" size="1.4rem" /> : <ChatOutlinedIcon />}
          </StyledIconButton>
        </Stack>
      </PageHeading>
      <StyledTabs
        value={currentTabIndex}
        textColor="inherit"
        indicatorColor="secondary"
        aria-label="participant detail tabs"
        onChange={onTabChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {tabsList.map(({ label, id }) => (
          <Tab key={id} value={id} label={label} />
        ))}
      </StyledTabs>
    </AppBar>
  );
}
