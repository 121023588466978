import { ReactElement } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

type Properties = BoxProps;

export function FallbackLoader({ ...rest }: Properties): ReactElement<Properties> {
  return (
    <Box sx={{ width: '100%', position: 'fixed', top: 0 }} {...rest}>
      <LinearProgress color="primary" aria-label="Fallback Loader" />
    </Box>
  );
}
