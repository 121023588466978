import {
  Alert,
  Box,
  BoxProps,
  CircularProgress,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';
import {
  MessageEntity,
  UserEntity,
  useMessagesQuery,
  useSendMmsMutation,
} from '../../../../graphql';
import { useCurrentUser, useUser } from '../../../../states';

import { Attachment } from '../../attachments';
import { Controls } from '../../controls';
import { Message } from '../Message';
import { MessageCardsPlaceholder } from '../../../atoms';
import NoMessageIcon from '../../../../assets/icons/no_message.svg';
import { PhoneNumberEntity } from '../../../../openapi';
import { UserRoles } from '../../../../constants/users';
import { theme } from '../../../../theme';
import { withValidStudyUsers } from '../../../hoc/withValidStudyUsers';

interface Properties extends BoxProps {
  readonly user: UserEntity | null;
  readonly onSelectMessage: (message: MessageEntity | null) => void;
  readonly selectedMessage: MessageEntity | null;
  readonly hasSearch?: boolean;
  readonly hasPagination?: boolean;
  phoneNumber?: PhoneNumberEntity;
}

export const scrollListToEnd = (reference: React.RefObject<HTMLUListElement>, smooth: boolean) => {
  if (reference.current) {
    reference.current.scrollTo({
      top: reference.current.scrollHeight,
      behavior: smooth ? 'smooth' : 'auto',
    });
  }
};

const MessagesComp = ({
  user,
  onSelectMessage,
  selectedMessage,
  hasSearch = true,
  hasPagination = true,
  phoneNumber,
  ...rest
}: Properties) => {
  const { t } = usePreferredTranslation();
  const { studyId, studyArmId, checkUserRole } = useUser();

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentMessages, setCurrentMessages] = useState<MessageEntity[]>([]);
  const lastVariablesReference = useRef({ page: 0, search: '', rowsPerPage: 10 });
  const currentMessagesReference = useRef<MessageEntity[]>([]);
  currentMessagesReference.current = currentMessages;

  const currentUser = useCurrentUser();
  const [sendMmsMutation, sendMmsMutationVariables] = useSendMmsMutation();

  const listReference = useRef<HTMLUListElement | null>(null);

  const messagesQuery = useMessagesQuery({
    fetchPolicy: 'network-only',
    variables: {
      search,
      user: Number(currentUser.data?.user?.id),
      correspondingUser: user?.id ? Number(user.id) : undefined,
      studyId,
      limit: {
        take: rowsPerPage,
        skip: page * rowsPerPage,
      },
    },
    skip: !currentUser.data?.user?.id,
  });

  useEffect(() => {
    lastVariablesReference.current = { page, search, rowsPerPage };
  }, [page, search, rowsPerPage]);

  useEffect(() => {
    if (messagesQuery.data) {
      const newMessages = [...messagesQuery.data.messages.items].reverse();
      const newVariables = {
        page,
        search,
        rowsPerPage,
      };

      if (
        newVariables.page === lastVariablesReference.current.page &&
        newVariables.search === lastVariablesReference.current.search &&
        newVariables.rowsPerPage === lastVariablesReference.current.rowsPerPage
      ) {
        const currentIds = currentMessagesReference.current.map((m) => m.id);
        const newIds = newMessages.map((m) => m.id);
        const hasNewMessages =
          newIds.some((id) => !currentIds.includes(id)) || newIds.length !== currentIds.length;

        if (hasNewMessages) {
          setCurrentMessages(newMessages);
        }
      } else {
        setCurrentMessages(newMessages);
        lastVariablesReference.current = newVariables;
      }
    }
  }, [messagesQuery.data, page, search, rowsPerPage]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!messagesQuery.loading) {
        await messagesQuery.refetch();
      }
    }, 3200);

    return () => clearInterval(intervalId);
  }, [messagesQuery]);

  const loading = currentUser.loading || messagesQuery.loading;

  useEffect(() => {
    scrollListToEnd(listReference, false);
  }, [loading]);

  useEffect(() => {
    scrollListToEnd(listReference, true);
  }, [currentMessages]);

  const count = messagesQuery.data?.messages.count || 0;

  const handlePageChange = (_: MouseEvent<HTMLButtonElement> | null, value: number) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setPage(0);
    },
    [setSearch, setPage],
  );

  const handleSearchReset = useCallback(() => {
    setSearch('');
    setPage(0);
  }, [setSearch, setPage]);

  const handleSubmitting = useCallback(
    async (text: string, attachments: Attachment[]) => {
      const { data, errors } = await sendMmsMutation({
        variables: {
          user: user?.id ? Number(user?.id) : null,
          text,
          files: attachments.map((attachment) => attachment.file),
          studyId,
          studyArmId,
          phoneNumber: phoneNumber ? JSON.stringify(phoneNumber) : '',
        },
      });

      if (errors) {
        toast.error(errors[0]?.message || 'Error');
        return;
      }

      // Reset to first page to ensure new messages are visible
      setPage(0);

      try {
        await messagesQuery.refetch();
      } catch {
        toast.error('Failed to refresh messages');
      }
    },
    [sendMmsMutation, studyArmId, studyId, user?.id, phoneNumber, messagesQuery, setPage],
  );

  // Keep the existing useEffect for automatic scrolling
  useEffect(() => {
    scrollListToEnd(listReference, true);
  }, [currentMessages]);

  const handleResetting = useCallback(() => {}, []);

  return (
    <Box
      {...rest}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflow: 'hidden',
      }}
    >
      {hasSearch && (
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TextField
            fullWidth
            placeholder={t('Search in chat')}
            type="text"
            variant="outlined"
            size="small"
            onChange={handleSearchChange}
            value={search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: search && (
                <IconButton aria-label="Reset search messages..." onClick={handleSearchReset}>
                  <CancelRoundedIcon />
                </IconButton>
              ),
            }}
          />
        </Box>
      )}
      {loading ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <List
          sx={{ p: 0, overflow: 'auto', flexGrow: 1, flexDirection: 'column-reverse' }}
          ref={(r) => {
            listReference.current = r;
          }}
        >
          {currentMessages.length === 0 &&
            (checkUserRole(currentUser.data?.user, UserRoles.PARTICIPANT) ? (
              <Box
                width="100%"
                height="100%"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  width="60%"
                  height="100%"
                >
                  <img src={NoMessageIcon} alt="No messages" />
                  <Typography variant="h4">{t('No messages')}</Typography>
                  <Typography variant="body1">
                    {t(
                      'Questions, conversations, and topics related to your study with our research team will show up here. If you have a question at any time, leave us a message and someone will get back to you.',
                    )}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <ListItem>
                <Alert sx={{ width: '100%' }} severity="error">
                  {t('Nothing found')}
                </Alert>
              </ListItem>
            ))}

          {currentMessages?.map((message: MessageEntity) => (
            <ListItem
              key={message.id}
              onClick={() => onSelectMessage(message)}
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                borderColor: theme.palette.divider,
                p: 1,
              }}
            >
              <Message
                owner={String(message.sender?.id) === String(currentUser.data?.user?.id)}
                message={message}
                isGroupChat={messagesQuery.data?.messages.isGroupChat}
              />
            </ListItem>
          ))}
        </List>
      )}
      {!loading && hasPagination && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={handlePageChange}
            labelRowsPerPage={t('Rows per page:')}
            nextIconButtonProps={{
              'aria-label': t('Go to next page'),
              title: t('Go to next page'),
            }}
            backIconButtonProps={{
              'aria-label': t('Go to previous page'),
              title: t('Go to previous page'),
            }}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Box>
      )}
      {!loading && (
        <Controls
          onSubmitting={handleSubmitting}
          onResetting={handleResetting}
          loading={messagesQuery.loading || sendMmsMutationVariables.loading}
          sx={{
            border: '1px solid',
            borderLeft: 'none',
            borderBottom: 'none',
            borderRight: 'none',
            borderColor: (t) => t.palette.divider,
          }}
        />
      )}
    </Box>
  );
};

const Messages = withValidStudyUsers(MessagesComp);

export { Messages };
