import { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

import { theme } from '../../../theme';

interface Properties extends BoxProps {
  name?: string;
  size?: number;
}

export const StyledBox = styled(Box)(() => ({
  background: theme.palette.primary.main,
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '19px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  borderRadius: '9999px',
}));

export function Avatar({
  name,
  size = 40,
  sx,
  children,
  ...rest
}: Properties): ReactElement<Properties> {
  const matches = (name || '').match(/\b\w/g) || [];
  const acronym = matches.join('').toUpperCase().slice(0, 3);

  return (
    <StyledBox
      sx={{
        width: `${size}px`,
        minWidth: `${size}px`,
        maxWidth: `${size}px`,
        minHeight: `${size}px`,
        maxHeight: `${size}px`,
        height: `${size}px`,
        ...sx,
      }}
      data-testid="avatarComponent"
      {...rest}
    >
      {acronym || children}
    </StyledBox>
  );
}
