import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { CircularProgress } from '@mui/material';
import { Stack } from '@mui/system';
import { toast } from 'react-toastify';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';
import { useClickOutside } from '../../../../hooks';
import { AssessmentQuestionEntity, GraphDataSourcesQuery } from '../../../../graphql';
import { LibraryEntity } from '../../../../openapi';
import { DropDown, DropdownContainer, OptionLabel, Options, OptionsContainer } from './styles';

type Item = LibraryEntity | AssessmentQuestionEntity;

const ExpandIcon = ({ expandMore }: { expandMore: boolean }) => {
  return expandMore ? <ExpandMoreIcon color="action" /> : <ExpandLessIcon color="action" />;
};

const getLabel = (item: Item) => {
  if ('title' in item) {
    return item.title;
  }
  if ('label' in item) {
    return item.label;
  }

  return '';
};

const RenderOption = ({
  option,
  pad,
  setSource,
  hideOptions,
  selectedDataSourceId,
  isIVR,
}: {
  option: Item;
  pad: number;
  setSource: (source: AssessmentQuestionEntity) => void;
  hideOptions: () => void;
  selectedDataSourceId: string | undefined;
  isIVR?: boolean;
}) => {
  const [expand, setExpand] = useState(false);
  const leftPad = pad + 10;

  const questions = useMemo(() => {
    if (option.type === 'resistance') {
      return (
        option?.questions?.map((question) => ({
          ...question,
          type: 'exercise', // add this type so that we can use this to show last elements also in dropdown only for exercise questions
          questions: question.options?.split(',').map((option) => ({
            label: option,
            id: question.id,
            questions: [],
            type: 'exercise-question', // adding this type to transform this question to show plots for these
          })),
        })) ?? []
      );
    }

    if (isIVR) {
      return (
        (option.questions ? [...option.questions] : [])
          .sort((a, b) => (a?.order || 0) - (b?.order || 0))
          .map((question) => ({
            ...question,
            type: 'ivr',
          })) ?? []
      );
    }

    return option?.questions ?? [];
  }, [option, isIVR]);

  const label = useMemo(() => getLabel(option), [option]);

  const shouldExpand = useMemo(() => {
    if (option.type === 'exercise' || (option.type === 'ivr' && questions.length > 0)) {
      return true; // show last questions also for exercise type
    }

    // Check if the current options have questions with more questions
    if (questions.length > 0) {
      for (const question of questions) {
        if ((question.questions && question.questions.length > 0) || question.type === 'cardio') {
          return true; // Expand if there are questions with more questions or if the question type is 'cardio'
        }
      }
    }

    return false;
  }, [questions, option]);

  const onClickAction = shouldExpand
    ? () => setExpand(!expand)
    : () => {
        setSource(option as AssessmentQuestionEntity);
        hideOptions();
      };

  return (
    <Options>
      <OptionLabel
        pad={leftPad}
        onClick={onClickAction}
        isSelected={selectedDataSourceId === option.id}
      >
        {label}
        {shouldExpand && <ExpandIcon expandMore={!expand} />}
      </OptionLabel>
      {expand &&
        shouldExpand &&
        questions.map((item: any) => {
          return (
            <RenderOption
              key={item.title ?? item.label}
              option={item}
              pad={leftPad}
              setSource={setSource}
              hideOptions={hideOptions}
              selectedDataSourceId={selectedDataSourceId}
              isIVR={item.type === 'ivr'}
            />
          );
        })}
    </Options>
  );
};

type Properties = {
  dataSource: AssessmentQuestionEntity | null;
  setDataSource: (dataSource: AssessmentQuestionEntity) => void;
  data?: GraphDataSourcesQuery;
};

export const DataSourceDropdown = ({ dataSource, setDataSource, data }: Properties) => {
  const { t } = usePreferredTranslation();
  const reference = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;
  const [showOptions, setShowOptions] = useState<boolean>(false);

  useClickOutside(reference, () => setShowOptions(false));

  useEffect(() => {
    if (data && data.libraries.items.length === 0) {
      toast.warning('No Data Source found for current Study, Please select correct Study.');
    }
  }, [data]);

  return (
    <DropdownContainer ref={reference}>
      <DropDown
        onClick={() => setShowOptions(!showOptions)}
        title={dataSource ? getLabel(dataSource) : t('Select Data Source')}
      >
        {dataSource ? <span>{getLabel(dataSource)}</span> : t('Select Data Source')}
        <ExpandIcon expandMore={!showOptions} />
      </DropDown>
      {showOptions && (
        <OptionsContainer>
          {data ? (
            data.libraries.items.length === 0 ? (
              <OptionLabel isSelected={false} pad={8} onClick={() => {}}>
                No DataSource found.
              </OptionLabel>
            ) : (
              data.libraries?.items.map((item) => {
                return (
                  <RenderOption
                    key={item.id}
                    option={item as unknown as Item}
                    pad={0}
                    setSource={setDataSource}
                    hideOptions={() => setShowOptions(false)}
                    selectedDataSourceId={dataSource?.id}
                    isIVR={item.type === 'ivr'}
                  />
                );
              })
            )
          ) : (
            <Stack alignItems="center" justifyContent="center" height="100%" width="100%" py={2}>
              <CircularProgress color="primary" size="1.5rem" />
            </Stack>
          )}
        </OptionsContainer>
      )}
    </DropdownContainer>
  );
};
