import { TextField } from '@mui/material';
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input';
import { ReactNode, useCallback } from 'react';
import { PhoneNumberInputType } from '../../graphql';
import { formatPhoneNumber } from '../../utils';

interface Properties {
  disabled?: boolean;
  editMode?: boolean;
  index?: number;
  labelDataTestId?: string;
  numberDataTestId?: string;
  label?: ReactNode | string;
  labelValue?: string;
  phoneNumber?: PhoneNumberInputType | null;
  onLabelChange?: (value: string, index?: number) => void;
  onChange?: (phoneNumber: ITelephoneInput, index?: number) => void;
}

export default function PhoneNumberField({
  index,
  disabled,
  editMode,
  labelDataTestId,
  numberDataTestId,
  label,
  labelValue,
  phoneNumber,
  onChange,
  onLabelChange,
}: Properties) {
  const onPhoneNumberChange = useCallback(
    (_value: any, info: MuiTelInputInfo) => {
      if (onChange) {
        onChange(
          {
            country: {
              numberValue: info.numberValue,
              countryCode: info.countryCode,
              countryCallingCode: info.countryCallingCode,
              nationalNumber: info.nationalNumber,
            },
          },
          index,
        );
      }
    },
    [index, onChange],
  );

  const handleLabelChange = useCallback(
    (event: any) => {
      if (onLabelChange) {
        onLabelChange(event.target.value, index);
      }
    },
    [index, onLabelChange],
  );
  return (
    <>
      {editMode && label ? (
        <TextField
          fullWidth
          disabled={disabled}
          inputProps={{
            'data-testid': labelDataTestId,
          }}
          label={label}
          value={labelValue}
          onChange={handleLabelChange}
          sx={{ mb: 3 }}
        />
      ) : (
        <span data-testid={labelDataTestId}>{labelValue}</span>
      )}
      {editMode && (
        <MuiTelInput
          fullWidth
          disabled={disabled}
          label={label && 'Phone number'}
          defaultCountry="US"
          value={phoneNumber?.country?.numberValue || ''}
          onChange={onPhoneNumberChange}
        />
      )}
      {!editMode && phoneNumber && (
        <span data-testid={numberDataTestId}>
          {label ? ', ' : ''}
          {phoneNumber.country?.numberValue}
        </span>
      )}
    </>
  );
}
